import { createSelector } from 'reselect';

const selectUsers = (state) => state.users;

export const getFetching = createSelector(
    [selectUsers],
    users => users.fetching
);

export const getError = createSelector(
    [selectUsers],
    users => users.error
);

export const getInvitationRequestError = createSelector(
    [selectUsers],
    users => users.invitationRequestError
);

export const getInvitationRequestFetching = createSelector(
    [selectUsers],
    users => users.invitationRequestFetching
);

export const getUsers = createSelector(
    [selectUsers],
    users => users !== null ? users.allUsers : null
);

export const getAllUsers = createSelector(
    [getUsers],
    allUsers => allUsers !== null ? allUsers.all : []
);

export const getInvitedUsers = createSelector(
    [selectUsers],
    users => users !== null ? users.invitedUsers : null
);

export const getWebdesigners = createSelector(
    [getUsers],
    allUsers => allUsers !== null ? allUsers.webdesigners.filter(webdesigner => webdesigner.statusName === 'Actif') : []
);

export const getIntegrators = createSelector(
    [getUsers],
    allUsers => allUsers !== null ? allUsers.integrators.filter(integrator => integrator.statusName === 'Actif') : []
);

export const getUser = createSelector(
    [selectUsers],
    users => users.currentUser
);