import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes/groups';
import axios from 'axios';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function loadAllGroups(token, keyword = null) {
    let params = {}
    if(keyword && keyword.length>0){
        params.searchword = keyword;
    }

    return axios({
        method: 'get',
        url: `${API_URI}/getGroupsList`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: params
    });
}

function loadGroup(id, token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getGroup`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            groupId: id
        }
    });
}

function createGroup(name, markets, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/addGroup`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            groupName: name,
            markets: markets,
            groupIsViewerOnly: false
        }
    });
}

function updateGroup(groupId, name, markets, groupIsMarket, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/addGroup`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            groupName: name,
            markets: markets,
            groupIsViewerOnly: false,
            groupId: groupId,
            groupIsMarket: groupIsMarket?1:0
        }
    });
}

function deletGroup(groupId, token){
    return axios({
        method: 'delete',
        url: `${API_URI}/deleteGroup`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            groupId: groupId
        }
    });
}

export function* workerLoadAllGroups(payload) {
    try{
        let keyword = payload.keyword || null;
        const token = yield select(getToken);
        const response = yield call(loadAllGroups, token, keyword);
        const groups = response.data;

        yield put({ type: types.LOAD_ALL_GROUPS_SUCCESS, groups });

    } catch (error) {
        yield put({ type: types.LOAD_ALL_GROUPS_FAILURE, error });
    }
}

export function* workerLoadGroup(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(loadGroup, payload.id, token);
        const group = response.data;

        yield put({ type: types.LOAD_GROUP_SUCCESS, group });

    } catch (error) {
        yield put({ type: types.LOAD_GROUP_FAILURE, error });
    }
}

export function* workerCreateGroup(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(createGroup, payload.name, payload.markets, token);
        const group = response.data;

        yield put({ type: types.CREATE_GROUP_SUCCESS, group });

    } catch (error) {
        yield put({ type: types.CREATE_GROUP_FAILURE, error });
    }
}

export function* workerUpdateGroup(payload){
    try{
        const token = yield select(getToken);
        const response = yield call(updateGroup, payload.groupId, payload.groupeName, payload.groupeMarkets, payload.groupIsMarket, token);
        const group = response.data;
        yield put({ type: types.UPDATE_GROUP_SUCCESS, group });
    }catch(error){
        yield put({ type: types.UPDATE_GROUP_FAILURE, error });
    }
}

export function* workerDeleteGroup(payload){
    try{
        const token = yield select(getToken);
        const groupId = payload.groupId;
        yield call(deletGroup, groupId, token);
        yield put({ type: types.LOAD_ALL_GROUPS_REQUEST });
    }catch(error){
        yield put({type: types.DELETE_GROUP_FAILURE, error })
    }
}

export function* watcherGroups() {
    yield takeLatest(types.LOAD_ALL_GROUPS_REQUEST, workerLoadAllGroups);
    yield takeLatest(types.LOAD_GROUP_REQUEST, workerLoadGroup);
    yield takeLatest(types.CREATE_GROUP_REQUEST, workerCreateGroup);
    yield takeLatest(types.UPDATE_GROUP_REQUEST, workerUpdateGroup);
    yield takeLatest(types.DELETE_GROUP_REQUEST, workerDeleteGroup);
}