import React from 'react';

import styles from './scss/stateBar.module.scss';

function StateBar({ actions, ...props }) {   

    return(
        <div className={props.active === 1 || props.active === 'undefined' ? styles.stepsContainer : styles.stepsContainer + ' ' + styles.closed}>
            {props.children}
        </div>
    );
}
 
export default StateBar

StateBar.protoTypes = {
}