import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/chip.module.scss';

function Chip(props) {

    return(
        <div className={styles.chipElement}>
            {props.editMode &&
                <span className={styles.deleteChipBtn} onClick={() => props.onClick(props.value)}>X</span>
            }
            <span className={styles.chipValue}>{ props.value }</span>
        </div>
    );
}

export default Chip;

Chip.protoTypes = {
    object: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired
}