import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

const root = document.getElementById('root');

if(root !== null) {
    ReactDOM.render(
        <App />,
        root
    );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();