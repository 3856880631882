import * as types from '../../constants/actionTypes/stats';

const initialState = {
  global: {},
  byMarkets: {},
  byIntegrators: {},
  fetching: false,
  error: null
}

function statistics(state = initialState, action) {
  switch(action.type) {
    case types.LOAD_STATS_REQUEST:
        return { ...state, fetching: true, error: null };

    case types.LOAD_STATS_SUCCESS:
        return { 
            ...state, 
           global: action.stats.global,
           byMarkets: action.stats.byMarkets,
           byIntegrators: action.stats.byIntegrators,
           fetching: false,
           error: null
        };
    
    case types.LOAD_STATS_FAILURE:
        return { ...state, fetching: false, error: action.error };

    default:
        return state;
  }

}

export default statistics;