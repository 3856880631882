function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  if (text !== null) {
    const urlified = text.replace(/<\/a>/g, '').replace(/<a\b[^>]*>/g, '').replace(urlRegex, function (url) {
      console.log(url);
      let cleanUrl = url.replace(/\s/g, "").replace(/<[^>]*>?/g, '').replace(/\&nbsp;/g, '');
      return '<a href="' + cleanUrl + '" target="_blank">' + cleanUrl + '</a>';
    });
    // console.log(urlified);
    return urlified;
  }
  // or alternatively
  // return text.replace(/<\/a>/g, '').replace(/<a\b[^>]*>/g, '').replace(urlRegex, '<a href="$1">$1</a>')
}


export default urlify;