import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';
import { FilePond, registerPlugin } from "react-filepond";
import FileViewer from 'react-file-viewer';
import styles from './scss/attachments.module.scss';
import 'filepond/dist/filepond.min.css';
import * as authSelectors from '../../selectors/auth';
import * as briefsSelectors from '../../selectors/briefs';

import Loader from '../../components/Loader';
import Button from '../../components/Button';

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import moment from 'moment';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function Attachments({ addFile, downloadFile, enableFile, removeFile, modifyFiles, permissions, requests, ...props}) {

    const [uploaders, setUploaders] = useState([{id: 0, title: ''}]);
    const [files, setFiles] = useState();

    const [documents, setDocuments] = useState(props.data.documents)

    const [filesModifications, setFilesModifications] = useState([])

    useEffect(() => {
        setFilesModifications([])
        setDocuments(props.data.documents)
    }, [props.editMode, props.data.documents])


    function addModif(modifInfo) {
        if (filesModifications.some(m=>m.documentId===modifInfo.documentId)) {
            setFilesModifications(filesModifications.filter(m=>m.documentId!==modifInfo.documentId))
        } else {
            setFilesModifications([...filesModifications, modifInfo])
        }
        setDocuments(
            documents.map(doc =>
                doc.documentId === modifInfo.documentId ? { ...doc, documentIsObsolet: doc.documentIsObsolet ? 0 : 1} : doc)
        )
    }

    function handleFilesModification(x) {
        if (filesModifications.length) {
            modifyFiles(filesModifications)
            // setFilesModifications([])
        }
    }


    function handleTitleChange(title, i) {
        setUploaders(
            uploaders.map(uploader =>
                uploader.id === i ? { ...uploader, title: title} : uploader
            )
        );
    }

    function handleUploadFile(fileItem, i) {
        addFile(props.data.briefId, uploaders[i].title, fileItem);
        setFiles([]);
    }

    function removeUploader(i) {
        if(uploaders.length > 1) {
            setUploaders(uploaders.filter(uploader => uploader.id !== i));
        }
    }

    function displayIcon(mimeType) {
        return (
            <img src={'/static/img/' + (mimeType.indexOf('image/') >= 0 ? 'picture' : 'selected-file') + '.svg' } alt={mimeType} />
        );
    }

    return(
        <div className={styles.attachmentsContainer}>
            <h4 className={styles.attachementContainerTitle}>Attachments</h4>

            {/* <button onClick={() => setUploaders([...uploaders, { id: uploaders[uploaders.length - 1].id + 1, title: '' }])}>+ Add Files</button> */}

            {props.data.briefActive === 1 && uploaders.map(uploader => {
                return (
                    <div key={`uploader-${uploader.id}`}>
                        {/* <input 
                            type="text" 
                            minLength="2" 
                            maxLength="255" 
                            placeholder="File name"
                            onChange={e => handleTitleChange(e.target.value, uploader.id)}
                        /> */}

                        {/* <button onClick={() => removeUploader(uploader.id)}>-</button> */}
                        <div className={styles.dropZone}>
                            <FilePond
                                files={files}
                                allowMultiple={false}
                                onupdatefiles={fileItem => {
                                    // handleUploadFile(fileItem[0], uploader.id)
                                }}
                                // onprocessfile={() => }
                                instantUpload={false}
                                server={{
                                    // fake server to simulate loading a 'local' server file and processing a file
                                    process: (fieldName, file, metadata, load) => {
                                        // simulates uploading a file
                                        handleUploadFile(file, uploader.id);
                                        load();
                                    },
                                    load: (source, load) => {
                                        // simulates loading a file from the server
                                        console.log('Loading ...');
                                    },
                                    revert: () => {
                                        console.log('revert ...');

                                    },
                                    restore: () => {
                                        console.log('restore ...');

                                    },
                                    fetch: () => {
                                        console.log('fetch ...');
                                    }
                                }}

                            />
                        </div>
                    </div>
                )
            })
            }


            {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=[https://www.your_website/file_name.pptx]' width='100%' height='600px' frameborder='0'/> */}

            {/* <FileViewer
                fileType={'ppt'}
                filePath={'/Users/sebastienthibaud/Downloads/NSD_INC0_Requirements\ Specifications_08042019\ \(1\).pptx'}
                // errorComponent={CustomErrorComponent}
                onError={(e) => console.log(e)}
            />

            <FileViewer
                fileType={'jpg'}
                filePath={'http://tous-logos.com/wp-content/uploads/2017/04/Walt-Disney-Logo-500x296.png'}
                // errorComponent={CustomErrorComponent}
                // onError={(e) => console.log(e)}
            /> */}


            {documents.map((document, i) => {

                let upload_date = moment(document.created_at);
                upload_date = upload_date.add(upload_date.utcOffset(), 'm'); 
                
                if(!document.documentIsObsolet || (permissions.brief_canUploadDocuments && props.editMode && document.documentIsObsolet)){
                    return(
                        <div className={styles.document} key={`document-${i}`}>
                            <div style={{opacity:document.documentIsObsolet?'0.3':'1'}} className={styles.docIcon}>{displayIcon(document.documentMimeType)}</div>
                            <div style={{opacity:document.documentIsObsolet?'0.3':'1'}} className={styles.docInfos}>
                                <div className={styles.docInfoLine}>
                                    <div style={{textDecoration:document.documentIsObsolet?'line-through':'none'}} className={styles.title}>{document.documentTitle}</div>
                                </div>
                                <div className={styles.docInfoLine}>
                                    {/* Creation Date */}<div className={styles.docDate}>Uploaded by <span style={{fontWeight: 'bold'}}>{document.user.userName}</span> at {upload_date.format('LL kk:mm')}</div>
                                    <div className={styles.docSize}>{document.documentSizeDisplay}</div>
                                </div>
                                
                            </div>
                            {/*
                            <div className={styles.docPreviewBtn}>
                                <img src={'/static/img/expand-arrows.svg'} title='preview' alt='preview' onClick={() => downloadFile(document.documentId, document.documentToken, document.documentFilename)} />                            
                            </div>
                            */}
                            <div style={{opacity:document.documentIsObsolet?'0.3':'1'}} className={styles.docDownloadBtn}>
                                <img src={'/static/img/download.svg'} title='download' alt='download' onClick={() => {
                                    if(!document.documentIsObsolet){
                                        downloadFile(document.documentId, document.documentToken, document.documentFilename);
                                    }
                                }} />
                            </div>
                            {props.editMode &&
                                <div className={styles.docDownloadBtn}>
                                    {(!document.documentIsObsolet) ? (
                                        <img src={'/static/img/remove.svg'} title='remove' alt='remove' onClick={() => addModif({modifType: types.DELETE_FILE_REQUEST, documentId: document.documentId, briefId: props.data.briefId, documentToken: document.documentToken})} />
                                    ) : (
                                        <img src={'/static/img/plus-button.svg'} title='add' alt='add' onClick={() => addModif({modifType: types.ENABLE_FILE_REQUEST, documentId: document.documentId, briefId: props.data.briefId, documentToken: document.documentToken})} />
                                    )}
                                </div>
                            }
                        </div>
                    );
                } else {
                    return null;
                }
            })}
            {props.editMode && filesModifications.length > 0 &&
                <div className={styles.actionBtnContainer}>
                    <Button secondary onClick={() => {
                    if(!requests.documents.fetching) {
                        setFilesModifications([])
                        setDocuments(props.data.documents)
                    }
                    }}>Cancel</Button>
                    {requests.documents.fetching ? (
                        <Button type="submit">
                            <Loader type="button" />
                        </Button>
                    ) : (
                        <Button onClick={() => handleFilesModification()}>Save</Button>
                    )}
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    permissions: authSelectors.getPermissions(state),
    requests: briefsSelectors.getRequests(state),
})

const mapDispatchToProps = (dispatch) => ({
    addFile: (briefId, fileName, file) => dispatch({ type: types.ADD_BRIEF_FILE_REQUEST, briefId: briefId, fileName: fileName, file: file }),
    downloadFile: (fileId, fileToken, fileName) => dispatch({ type: types.DOWNLOAD_FILE_REQUEST, id: fileId, fileToken: fileToken, fileName: fileName }),
    modifyFiles: (filesToModify) => dispatch({ type: types.MODIFY_FILES_REQUEST, modifications: filesToModify }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Attachments);