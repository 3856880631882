import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes/search';
import axios from 'axios';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function search(keywords, cursor = -1, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/searchBrief`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            searchword: keywords,
            ...(cursor !== -1 ? { start: cursor } : {})
        }
    });
}

export function* workerSearch(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(search, payload.keywords, -1, token);
        const results = response.status === 200 ? response.data : null;

        yield put({ type: types.SEARCH_SUCCESS, results });

    } catch (error) {
        yield put({ type: types.SEARCH_FAILURE, error })
    }
}

export function* workerLoadNextResults(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(search, payload.keywords, payload.cursor, token);
        const results = response.status === 200 ? response.data.items : null;

        yield put({ type: types.LOAD_NEXT_SEARCH_RESULTS_SUCCESS, results });

    } catch (error) {
        console.log(error);
        yield put({ type: types.LOAD_NEXT_SEARCH_RESULTS_FAILURE, error })
    }
}

export function* watcherSearch() {
    yield takeLatest(types.SEARCH_REQUEST, workerSearch);
    yield takeLatest(types.LOAD_NEXT_SEARCH_RESULTS_REQUEST, workerLoadNextResults);
}