import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';
import * as briefsSelectors from '../../selectors/briefs';
import * as authSelectors from '../../selectors/auth';
import ErrorBoundary from '../../components/Helper/ErrorBoundary';
import Error from '../../components/Error';
import DatePicker from 'react-datepicker';
import getDay from 'date-fns/getDay';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import moment from 'moment';
import momentTz from 'moment-timezone';
import striptags from 'striptags';
import PageHeader from '../../components/PageHeader';
import StateBar, { State, StateCategory } from '../../components/Steps';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Modal from '../../components/Modal';
import styles from './index.module.scss';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from '../../components/Loader';

import BriefInfo from '../../components/BriefInfo';
import Button from '../../components/Button';
import History from '../../components/History';
import Attachments from '../../components/Attachments';

import Editor from '../../components/Editor';
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes';

// const initialState = {txt1: EditorState.createEmpty(), txt2: EditorState.createEmpty()};

// function reducer(state, action) {
//   switch (action.type) {
//     case 'increment':
//       return {...state, txt1: state.txt};
//     case 'decrement':
//       return {...state, txt2: state.txt};
//     default:
//       throw new Error();
//   }
// }

function BriefView({ fetching, briefCache, brief, error, requests, comments, workflow, canPassStep, sortedStates, permissions, account, loadBrief, changeLiveDate, updateState, addComment, toggleCloseBrief, parallelizeState, unparallelizeState, ...props }) {
    const [edit, setEdit] = useState(false);
    const [previsouStepModal, setPrevisouStepModal] = useState(false);
    const [closeBriefModal, setCloseBriefModal] = useState(false);
    const [liveDate, setLiveDate] = useState(null);

    const [validationComment, setValidationComment] = useState(EditorState.createEmpty());
    const [validationComment2, setValidationComment2] = useState(EditorState.createEmpty());

    // const [commentState, dispatch] = useReducer(reducer, initialState);

    const [comment, setComment] = useState(EditorState.createEmpty());
    const [commentEditorVisibility, setCommentEditorVisibility] = useState(false)
    const [previousStateComment, setPreviousStateComment] = useState(EditorState.createEmpty())
    const [closeComment, setCloseComment] = useState(EditorState.createEmpty())
    const [previousState, setPreviousState] = useState(null)
    const [previousStates, setPreviousStates] = useState([])
    const [stateCategoryExpanded, setStateCategoryExpanded] = useState(null)


    // console.log(commentState);

    useEffect(() => {
        loadBrief();
    }, [loadBrief]);

    useEffect(() => {
        let previousStates = workflow.reduce((acc, curr) => {
            return [...acc, ...curr.substeps.filter(s => s.stateOrder > 0)]
        }, []).map(i => ({ label: i.stateName, value: i.stateId }));
        setPreviousStates(previousStates);

    }, [brief, workflow])

    useEffect(() => {
        if (brief !== null) {
            setLiveDate(new Date(brief.briefLiveDate));
        }
    }, [edit, brief])

    if (fetching || brief === null) {
        brief = briefCache !== null ? briefCache : brief;
    }

    function offDays(date) {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    }

    function goToPreviousState(stateId, previousStateId) {
        const commentContent = draftToHtml(convertToRaw(previousStateComment.getCurrentContent())).replace(/\s+/g, ' ').trim();
        updateState(stateId, previousStateId, commentContent, 5);
        setValidationComment(EditorState.createEmpty());
        setPrevisouStepModal(false);
    }

    function changeState(stateId, newStateId, evolutionType, index) {

        const commentContent = draftToHtml(convertToRaw(validationComment.getCurrentContent())).replace(/\s+/g, ' ').trim();
        const commentContent2 = draftToHtml(convertToRaw(validationComment2.getCurrentContent())).replace(/\s+/g, ' ').trim();

        if (index === 1) {
            updateState(stateId, newStateId, commentContent2, evolutionType);
        } else {
            updateState(stateId, newStateId, commentContent, evolutionType);
        }
        setValidationComment(EditorState.createEmpty());
        setValidationComment2(EditorState.createEmpty());
    }

    function sendComment() {
        const commentContent = draftToHtml(convertToRaw(comment.getCurrentContent())).replace(/\s+/g, ' ').trim();

        if (striptags(commentContent).length > 0) {
            addComment(commentContent);
            setCommentEditorVisibility(false);
            setComment(EditorState.createEmpty());
        }
    }

    function closeBrief(briefActive, closeComment) {
        const closeCmt = draftToHtml(convertToRaw(closeComment.getCurrentContent())).replace(/\s+/g, ' ').trim();
        toggleCloseBrief(briefActive, closeCmt);
        setCloseBriefModal(false);
    }


    if (fetching) {
        // console.log(brief);
        return (
            <Loader />
        );
    }

    if (error !== null) {
        return (
            <Error error={error} />
        );
    }

    if ((brief === null && briefCache === null) || permissions === null || (fetching && brief.briefId !== parseInt(props.match.params.id)) || sortedStates === null || workflow === null) {
        return (
            <Loader />
        );
    }


    const closeLabel = brief === null || brief.briefActive === 0 ? 'Open Brief' : 'Close Brief';
    let numStep = 1;

    // <>{moment(brief.briefLiveDate).format('ddd, YYYY/MM/DD')}</>

    // var date = new Date(brief.briefLiveDate + ' UTC');
    let briefLiveDate = moment(brief.briefLiveDate).format('ddd, YYYY/MM/DD');

    return (
        <ErrorBoundary>
            <PageHeader
                editMode={edit}
                title={brief.briefTitle}
                subtitle={`#${brief.briefId}`}
                briefId={props.match.params.id}
                briefType={brief.typeId}
            >
                {brief.briefActive === 1 &&
                    <div className={styles.pageHeaderBtnContainer}>
                        {(permissions.brief_canAssignIntegrator ||
                            permissions.brief_canAssignWebdesigner ||
                            permissions.brief_canUpdateWeight ||
                            permissions.brief_canUpdatePlatform ||
                            permissions.brief_canUpdateBriefInfo ||
                            permissions.brief_canUploadDocuments
                        ) &&
                            edit ?
                            <Button primary onClick={() => setEdit(!edit)} className={styles.editBtn}>Finish Editing</Button>
                            :
                            <Button tertiary onClick={() => setEdit(!edit)} className={styles.editBtn}>Edit</Button>
                        }
                        {permissions.brief_canSendBack && sortedStates.length === 1 && previousStates.length > 0 &&
                            <Button tertiary onClick={() => setPrevisouStepModal(true)}>Update Step</Button>
                        }
                        {permissions.brief_canClose &&
                            <>
                                {requests.closeBrief.fetching ? (
                                    <Loader />
                                ) : (
                                    // <Button clickToConfirm tertiary onClick={() => toggleCloseBrief(brief.briefActive)}>{closeLabel}</Button>
                                    <Button tertiary onClick={() => setCloseBriefModal(true)}>{closeLabel}</Button>
                                )
                                }
                                {requests.closeBrief.error !== null &&
                                    <div>{requests.closeBrief.error}</div>
                                }
                            </>
                        }
                        {permissions.brief_canParallelize &&
                            <>
                                {sortedStates.length === 1 && sortedStates[0].simultaneity &&
                                    sortedStates[0].simultaneity.map((simAction, i) => {
                                        return <Button clickToConfirm tertiary onClick={() => parallelizeState(sortedStates[0].stateId, simAction.id, '', simAction.type)} key={i}>{simAction.btnLabel}</Button>
                                    })
                                }
                                {sortedStates.length > 1 &&
                                    <Button clickToConfirm tertiary onClick={() => unparallelizeState('', 5)}>Unparallelize Brief</Button>
                                }
                            </>
                        }
                    </div>
                }

            </PageHeader>

            {/* Previous step modal */}
            <Modal
                isOpen={previsouStepModal}
                ariaHideApp={false}
                onRequestClose={() => setPrevisouStepModal(false)}
            >
                {previsouStepModal &&
                    <div className={styles.Modal} >
                        <h1>Update step</h1>
                        <div className={styles.stepsWrapper}>
                            <div className={styles.stepContainerFrom}>
                                <label className={styles.label}>From :</label>
                                <div>
                                    <p>{sortedStates[0].stateName}</p>
                                </div>
                            </div>
                            <div className={styles.stepContainerTo}>
                                <label className={styles.label}>To: </label>
                                <ReactMultiselectCheckboxes
                                    allowSelectAll={true}
                                    className='brf-select-container'
                                    classNamePrefix='brf-select'
                                    id=""
                                    placeholderButtonLabel="To"
                                    isMulti={false}
                                    options={previousStates}
                                    onChange={setPreviousState}
                                    value={previousState}
                                />
                            </div>
                        </div>
                        <Editor
                            editorState={previousStateComment}
                            onEditorStateChange={(txt) => setPreviousStateComment(txt)}
                        />
                        <Button onClick={() => goToPreviousState(sortedStates[0].stateId, previousState.value)}>Update</Button>
                    </div>
                }
            </Modal>

            {/* Close brief modal */}
            <Modal
                isOpen={closeBriefModal}
                ariaHideApp={false}
                onRequestClose={() => setCloseBriefModal(false)}
            >
                {closeBriefModal &&
                    <div className={styles.Modal} >
                        <h1>Comment</h1>
                        <Editor
                            editorState={closeComment}
                            onEditorStateChange={(txt) => setCloseComment(txt)}
                        />
                        <Button onClick={() => closeBrief(brief.briefActive, closeComment)}>Close brief</Button>
                    </div>
                }
            </Modal>


            <div className={[styles.workflowContainer, (canPassStep && brief.briefActive === 1) ? styles.workFlowContainerBorder : ''].join(' ')}>
                {fetching &&
                    <Loader />
                }

                {workflow.length && sortedStates.length &&
                    <div className={styles.workflow}>

                        <StateBar active={brief.briefActive}>
                            {workflow.map((stateCategory, i) => {
                                if (stateCategory.substeps) {

                                    return (
                                        <StateCategory key={`state-${i}`} order={numStep++}
                                            status={stateCategory.status}
                                            title={stateCategory.categoryName}
                                            expanded={stateCategory.status === 'process' || i === stateCategoryExpanded}
                                            onClick={() => {
                                                if (stateCategory.status !== 'process') {
                                                    setStateCategoryExpanded(i !== stateCategoryExpanded ? i : null)
                                                }
                                            }}
                                        >
                                            {stateCategory.substeps.map((state, j) => {
                                                let icon =
                                                    state.status === 'finish' ? 'check' :
                                                        state.stateIcon ? state.stateIcon :
                                                            state.status === 'wait' ? 'task' :
                                                                state.stateName.toLowerCase().indexOf('waiting') > -1 ? 'waiting' : 'in-progress'
                                                    ;
                                                return (<State key={j}
                                                    icon={icon}
                                                    status={state.status}
                                                    name={(state.status === 'finish' && state.validatedStateLabel) ? state.validatedStateLabel : state.stateName} />)
                                            })}
                                        </StateCategory>
                                    )
                                } else {
                                    return (
                                        // <Step key={`state-${i}`} order={i+1} status={state.status} title={state.stateName}></Step>
                                        <StateCategory key={`state-${i}`} order={i} title={stateCategory.categoryName}></StateCategory>
                                    )
                                }
                            })}
                        </StateBar>

                        <div className={styles.liveDateCtn}>
                            <label className={styles.liveDateLabel}>Live date</label>
                            <div className={styles.liveDate}>
                                {edit ? (

                                    <DatePicker
                                        selected={liveDate}
                                        onChange={day => {
                                            setLiveDate(day);
                                        }}
                                        // showTimeSelect
                                        monthsShown={1}
                                        minDate={new Date()}
                                        autoComplete="off"
                                        filterDate={offDays}
                                        minTime={setHours(setMinutes(new Date(), 30), 9)}
                                        maxTime={setHours(setMinutes(new Date(), 30), 18)}
                                        dateFormat="eee, yyyy/MM/dd"
                                        className={styles.liveDateInput}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            }
                                        }}
                                    />
                                ) : (
                                    <>{briefLiveDate}</>
                                )
                                }
                            </div>

                            {edit &&
                                <div className={styles.liveDateActionWrapper}>
                                    {liveDate && brief.briefLiveDate &&
                                        new Date(liveDate).getTime() !== new Date(brief.briefLiveDate).getTime() && (
                                            <div className={styles.liveDateActionCtn}>
                                                <Button secondary onClick={() => {
                                                    setLiveDate(new Date(brief.briefLiveDate));
                                                }}>Cancel</Button>
                                                {requests.liveDate.fetching ? (
                                                    <Button type="submit">
                                                        <Loader type="button" />
                                                    </Button>
                                                ) : (
                                                    <Button onClick={() => {
                                                        changeLiveDate(liveDate);
                                                    }}>Save</Button>
                                                )}
                                            </div>
                                        )}
                                    {requests.liveDate.error &&
                                        <div className={styles.liveDateError}>{requests.liveDate.error.response.statusText}</div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

                {canPassStep && brief.briefActive === 1 &&
                    // If permissions to pass step is only for some states, returns array of concerned states
                    (Array.isArray(permissions.brief_canPassStep) ? sortedStates.filter(s => permissions.brief_canPassStep.includes(s.stateId)) : sortedStates).map((state, i) => {
                        if (Array.isArray(state.nextStates)) {
                            return (
                                <div className={styles.workflowActionContainer} key={i}>
                                    {sortedStates.length > 1 &&
                                        <p>Action for {state.stateName}</p>
                                    }

                                    {(i === 0) ?
                                        (
                                            <Editor
                                                editorState={validationComment}
                                                placeholder="Type a comment"
                                                onEditorStateChange={(txt) => setValidationComment(txt)}
                                            />
                                        )
                                        :
                                        (
                                            <Editor
                                                editorState={validationComment2}
                                                placeholder="Type a comment"
                                                onEditorStateChange={(txt) => setValidationComment2(txt)}
                                            />
                                        )
                                    }


                                    <div className={styles.worflowBtnContainer}>

                                        {state.nextStates !== null &&
                                            requests.states.fetching ?
                                            <Button type="submit">
                                                <Loader type="button" />
                                            </Button>
                                            :
                                            state.nextStates.map((nextState, j) => {
                                                const primary = j === state.nextStates.length - 1;
                                                return (
                                                    <Button
                                                        primary={primary}
                                                        secondary={!primary}
                                                        key={j}
                                                        onClick={() => changeState(state.stateId, nextState.id, nextState.type, i)}
                                                    >{nextState.btnLabel}</Button>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>

            <div className={styles.container}>
                <div className={styles.left}>

                    <BriefInfo data={brief} editMode={edit} />

                    <Attachments editMode={edit} data={brief} />
                </div>

                <div className={styles.right}>
                    <div className={styles.historySectionHeader}>
                        <div className={styles.historySectionHeaderTop}>
                            <h4>History</h4>
                            {brief.briefActive === 1 && permissions.brief_canComment && !commentEditorVisibility &&
                                <Button tertiary onClick={() => { setCommentEditorVisibility(true) }}>Comment</Button>
                            }
                        </div>
                        {commentEditorVisibility &&
                            <div className={styles.commentActionContainer}>
                                <Editor
                                    editorState={comment}
                                    placeholder="Type a comment"
                                    onEditorStateChange={(txt) => setComment(txt)}
                                />
                                <div className='actions'>
                                    {comments.fetching ? (
                                        <Button type="submit">
                                            <Loader type="button" />
                                        </Button>
                                    ) : (
                                        <Button onClick={() => sendComment()}>Send Comment</Button>
                                    )}

                                    <Button secondary onClick={() => { setCommentEditorVisibility(false) }}>Cancel</Button>
                                </div>

                            </div>
                        }
                    </div>

                    {comments.fetching &&
                        <Loader />
                    }

                    <History logs={brief.logs} />
                </div>
            </div>

        </ErrorBoundary>
    );
}

const mapStateToProps = (state, ownProps) => ({
    fetching: briefsSelectors.getFetching(state),
    briefCache: briefsSelectors.getBriefById(state, parseInt(ownProps.match.params.id)),
    brief: briefsSelectors.getBrief(state),
    error: briefsSelectors.getError(state),
    requests: briefsSelectors.getRequests(state),
    comments: briefsSelectors.getComments(state),
    permissions: authSelectors.getPermissions(state),
    account: authSelectors.getAccount(state),
    sortedStates: briefsSelectors.getSortedStates(state),
    workflow: briefsSelectors.getWorkflow(state),
    canPassStep: briefsSelectors.getCanPassStep(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadBrief: () => dispatch({ type: types.LOAD_BRIEF_REQUEST, id: ownProps.match.params.id }),
    changeLiveDate: (liveDate) => dispatch({ type: types.CHANGE_LIVE_DATE_REQUEST, id: ownProps.match.params.id, liveDate: liveDate }),
    updateState: (stateId, newStateId, validationComment, evolutionType) => dispatch({ type: types.UPDATE_STATE_REQUEST, id: ownProps.match.params.id, stateId: stateId, newStateId: newStateId, validationComment: validationComment, evolutionType: evolutionType }),
    parallelizeState: (stateId, newStateId, validationComment, evolutionType) => dispatch({ type: types.PARALLELIZE_STATE_REQUEST, id: ownProps.match.params.id, stateId: stateId, newStateId: newStateId, validationComment: validationComment, evolutionType: evolutionType }),
    unparallelizeState: (validationComment, evolutionType) => dispatch({ type: types.UNPARALLELIZE_STATE_REQUEST, id: ownProps.match.params.id, validationComment: validationComment, evolutionType: evolutionType }),
    addComment: (comment) => dispatch({ type: types.ADD_COMMENT_REQUEST, briefId: ownProps.match.params.id, comment: comment }),
    toggleCloseBrief: (status, closeComment) => dispatch({ type: types.TOGGLE_CLOSE_BRIEF_REQUEST, id: ownProps.match.params.id, status: status, closeComment: closeComment })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BriefView);