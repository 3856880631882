import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/search';
import * as selectors from '../../selectors/search';
import styles from './scss/searchbar.module.scss';
import Item from '../BriefList/Item';
import Loader from '../Loader';


function SearchBar({ fetching, results, total, requests, error, searchRequest, loadNextResults, ...props }) {

    const [searchQuery, setSearchQuery] = useState('');
    const resultContainer = useRef(null);
    const refScrollContainer = useRef(null);
    const [step, setStep] = useState(0);
    const [cursor, setCursor] = useState(-1);

    const nbResultsByStep = 10;

    useEffect(() => {
        setStep(0);
    }, [searchQuery]);

    const maxSteps = total > nbResultsByStep ? Math.ceil(total / nbResultsByStep) : 0;

    const handleScroll = useCallback(
        () => {
            if(refScrollContainer.current !== null) {
                const mainContainerPosition = refScrollContainer.current.getBoundingClientRect().bottom;
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                if(mainContainerPosition <= viewportHeight && !requests.nextResults.fetching && step < maxSteps) {
                    setStep(step + 1);
                    loadNextResults(searchQuery, cursor);
                }
            }
        },
        [requests, step, maxSteps, loadNextResults, searchQuery, cursor]
    );
    

    useEffect(() => {
        const scrollRef = resultContainer.current;

        if(searchQuery.length > 0 && scrollRef !== null) {
            scrollRef.addEventListener('scroll', handleScroll);
        }

        return () => scrollRef !== null ? scrollRef.removeEventListener('scroll', handleScroll) : null;  
    }, [handleScroll, searchQuery]);


    useEffect(() => {
        if(results !== null && results.length > 0) {
            setCursor(nbResultsByStep * (step + 1));
        }
    }, [results, step]);


    function search(e) {
        e = e || window.event; // Fix Firefox issue.
        const keywords = e.target.value;
        
        handleSearchState(keywords)

        if(keywords.length > 1) {
            searchRequest(keywords);
        }
    }

    function handleSearchState(val) {
        val.length > 0 ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
        setSearchQuery(val)
    }

    function handleClickOutside(e) {
        if (resultContainer.current && !resultContainer.current.contains(e.target)) {
            handleSearchState('')
        }
    }

    function submitHandler(e) {
        e.preventDefault()
        props.onSubmit(e)
    }


    function searchbarcomponent(autoFocus = false) {
        return (
            <form method="get" className={styles.searchbarContainer} action="" onSubmit={submitHandler}>
                <button type="button" className={styles.searchButton}>
                    {fetching && searchQuery !== '' ? (
                        <Loader className={styles.loader} />
                    ) : (
                        <div className={styles.searchIcon}></div>
                    )
                    }
                </button>
                <input 
                    // autoFocus={autoFocus}
                    onChange={e => search(e)} 
                    // type="search" 
                    name="searchQuery" 
                    placeholder={props.placeholder} 
                    required="" 
                    className={styles.searchbarInput} 
                    autoComplete="off"
                    maxLength="254"
                    value={searchQuery}
                />
            </form>
        )
    }

    return (
        <>
            {searchbarcomponent()}
            {searchQuery !== '' &&
                ReactDOM.createPortal(
                    <div className={styles.searchOverlay} onClick={handleClickOutside}>
                        <div className={styles.resultContainer} ref={resultContainer}>
                            {/* <div className={styles.closeBtnContainer}>
                                <button className={styles.closeBtn} onClick={() => handleSearchState('')} >X</button>
                            </div> */}

                            <div className={styles.resultActionContainer}>
                                {searchbarcomponent(true)}
                            </div>
                    
                            <div className={styles.totalResults}>{total > 1 ? `${total} results` : `${total} result` }</div>

                            {results !== null &&
                                <ul ref={refScrollContainer}>
                                    {results.map((result, i) => {
                                        return(
                                            <li onClick={()=>{document.body.style.overflow = '';}} key={`result-${i}`}>
                                                <Item small brief={result} />
                                            </li>
                                        );
                                    })}
                                </ul>
                            }

                            {error &&
                                <div>{error.response?error.response.status:''} - {error.response?error.response.statusText:''}</div>
                            }

                            {requests.nextResults.fetching &&
                                <Loader/>
                            }

                            {requests.nextResults.error &&
                                <div>{requests.nextResults.error.response.status} - {requests.nextResults.error.response.statusText}</div>
                            }
                        </div>
                    </div>
                , document.body)
            }
            
        </>
    );
}

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    results: selectors.getResults(state),
    total: selectors.getTotal(state),
    requests: selectors.getRequests(state),
    error: selectors.getError(state)
});

const mapDispatchToProps = (dispatch) => ({
    searchRequest: (keywords) => dispatch({ type: types.SEARCH_REQUEST, keywords: keywords}),
    loadNextResults: (keywords, cursor) => dispatch({ type: types.LOAD_NEXT_SEARCH_RESULTS_REQUEST, keywords: keywords, cursor: cursor })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBar);