import React from 'react';
import styles from './scss/index.module.scss';
import ReactModal from 'react-modal';

export default function Modal(props){
    return (
        <ReactModal
            isOpen={props.isOpen}
            className={styles.modal}
            ariaHideApp={props.ariaHideApp}
            onRequestClose={() => props.onRequestClose(false)}
        >
            {props.children}
        </ReactModal>
    )
}