import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import rootSaga from '../sagas';

export const history = createBrowserHistory();

// Performance Test Middleware
const userTimingMiddleware = () => (next) => (action) => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`,
  );
  return result;
}


export default function configureStore(preloadedState) {
    const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware,
            userTimingMiddleware
        ),
        ),
    );

    sagaMiddleware.run(rootSaga);

    return store
};