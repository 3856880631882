import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import styles from './scss/layout.module.scss';
import Loader from '../components/Loader';

function LoginLayout({ component: MatchedPage, ...props }) {
    const style = {
		height: props.height
    }

    return(
        <Route {...props} render={matchProps => (
            <div className={styles.loginLayout} style={style}>
                <div className={styles.loginContainer}>
                    {/* <h1>Briefgen</h1> */}
                    <Suspense fallback={<Loader />}>
                        <MatchedPage {...matchProps} />
                    </Suspense>
                </div>
            </div>
        )} />
    );
}

export default LoginLayout;