import React from 'react';
import { Route } from 'react-router-dom';
import { spring, AnimatedSwitch } from 'react-router-transition';
import useBodyHeightResizer from '../hooks/useBodyHeightResizer';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
// LAYOUTS
import DefaultLayout from '../Layout';
import LoginLayout from '../Layout/LoginLayout';
// PAGES
import Error from '../pages/Error';
import BriefCreation from '../pages/BriefCreation'; // TODO - Check why there is an issue with CSS when we use code-splitting.
import BriefView from '../pages/BriefView'; // TODO - Check why there is an issue with CSS when we use code-splitting.

const Login = React.lazy(() => import('../pages/Login'));
const SignUp = React.lazy(() => import('../pages/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const ForgetPasswordConfirmation = React.lazy(() => import('../pages/ForgetPasswordConfirmation'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const ResetPasswordConfirmation = React.lazy(() => import('../pages/ResetPasswordConfirmation'));
const Logout = React.lazy(() => import('../pages/Logout'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const UserList = React.lazy(() => import('../pages/UserList'));
const User = React.lazy(() => import('../pages/User'));
const GroupList = React.lazy(() => import('../pages/GroupList'));
const MyAccount = React.lazy(() => import('../pages/MyAccount'));
const Statistics = React.lazy(() => import('../pages/Statistics'));


function MainRouter() {

    const sidenavContainer = React.createRef();
    const layoutHeight = useBodyHeightResizer(); // TODO - Can be improved

    // we need to map the `scale` prop we define below
    // to the transform style property
    function mapStyles(styles) {
        return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
        };
    }
    
    // wrap the `spring` helper to use a bouncy config
    function bounce(val) {
        return spring(val, {
        stiffness: 330,
        damping: 22,
        });
    }
    
    // child matches will...
    const bounceTransition = {
        // start in a transparent, upscaled state
        atEnter: {
        opacity: 0,
        scale: 1.2,
        },
        // leave in a transparent, downscaled state
        atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
        },
        // and rest at an opaque, normally-scaled state
        atActive: {
        opacity: bounce(1),
        scale: bounce(1),
        },
    };

    return(
        <>
            <div id="sidenavContainer" ref={sidenavContainer} />
            <ScrollToTop>
                <AnimatedSwitch
                    atEnter={bounceTransition.atEnter}
                    atLeave={bounceTransition.atLeave}
                    atActive={bounceTransition.atActive}
                    mapStyles={mapStyles}
                    className="route-wrapper"
                >
                    <LoginLayout exact path="/login" component={Login} height={layoutHeight} />
                    <LoginLayout exact path="/signup/:token" component={SignUp} height={layoutHeight} />
                    <LoginLayout exact path="/forget-password" component={ForgetPassword} height={layoutHeight} />
                    <LoginLayout exact path="/forget-password-confirmation" component={ForgetPasswordConfirmation} height={layoutHeight} />
                    <LoginLayout exact path="/reset-password/:token" component={ResetPassword} height={layoutHeight} />
                    <LoginLayout exact path="/reset-password-confirmation" component={ResetPasswordConfirmation} height={layoutHeight} />
                    
                    <PrivateRoute exact path="/" component={Dashboard} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/brief-creation" component={BriefCreation} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/offer-brief-creation" component={BriefCreation} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/brief/:id" component={BriefView} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/user-list" component={UserList} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/user/:id" component={User} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/group-list" component={GroupList} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path='/my-account' component={MyAccount} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path='/statistics' component={Statistics} layout={DefaultLayout} sidenavContainer={sidenavContainer} height={layoutHeight}/>
                    <PrivateRoute exact path="/logout" component={Logout} height={layoutHeight} />
            
                    <Route component={Error} height={layoutHeight} />
                </AnimatedSwitch>
            </ScrollToTop>
        </>
    );
}

export default MainRouter;