import { all } from 'redux-saga/effects';
import * as auth from './auth';
import * as briefs from './briefs';
import * as formFields from './formFields';
import * as search from './search';
import * as users from './users';
import * as groups from './groups';
import * as stats from './stats';


export default function* rootSaga() {
    yield all([
        auth.watcherAuthentication(),
        briefs.watcherBriefs(),
        formFields.watcherFormFields(),
        search.watcherSearch(),
        users.watcherUsers(),
        groups.watcherGroups(),
        stats.watcherStats()
    ]);
};