import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes/formFields';
import axios from 'axios';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function loadFormFields(token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getLists`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

function loadAllMarkets(token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getMarketsList`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export function* workerLoadFormFields() {
    try{
        const token = yield select(getToken);
        const response = yield call(loadFormFields, token);
        const formFields = response.data;
        // console.log(formFields);

        yield put({ type: types.LOAD_FORM_FIELDS_SUCCESS, formFields });

    } catch (error) {
        yield put({ type: types.LOAD_FORM_FIELDS_FAILURE, error });
    }
}

export function* workerLoadAllMarkets() {
    try{
        const token = yield select(getToken);
        const response = yield call(loadAllMarkets, token);
        const markets = response.data;

        yield put({ type: types.LOAD_MARKETS_SUCCESS, markets });

    } catch (error) {
        yield put({ type: types.LOAD_MARKETS_FAILURE, error });
    }
}

export function* watcherFormFields() {
    yield takeLatest(types.LOAD_FORM_FIELDS_REQUEST, workerLoadFormFields);
    yield takeLatest(types.LOAD_MARKETS_REQUEST, workerLoadAllMarkets);
}