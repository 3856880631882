import { createSelector } from 'reselect';
import { isArray } from 'util';
import * as authSelectors from '../auth';
import * as formFieldsSelectors from '../formFields';

const selectBriefs = state => state.briefs || null;
const selectBriefId = (state, briefId) => briefId;

export const getRequests = createSelector(
    [selectBriefs],
    briefs => briefs !== null ? briefs.requests : null
);

export const getFetchDescription = createSelector(
    [selectBriefs],
    briefs => briefs !== null ? briefs.description : null
);

export const getFetching = createSelector(
    [selectBriefs],
    briefs => briefs.fetching
);

export const getAllBriefs = createSelector(
    [selectBriefs],
    briefs => briefs !== null ? briefs.allBriefs : null
);

export const getBriefs = createSelector(
    [getAllBriefs],
    allBriefs => allBriefs !== null ?  allBriefs.items : null
);

export const getBriefById = createSelector(
    [getBriefs, selectBriefId],
    (allBriefs, briefId) => {
        if(allBriefs !== null) {
            for(const brief of allBriefs) {
                if(brief.briefId === briefId){
                    return brief;
                }
            }
        }
        
        return null;
    }
);

export const getTotalBriefs = createSelector(
    [getAllBriefs],
    allBriefs => allBriefs.total
);

export const getBrief = createSelector(
    [selectBriefs],
    briefs => briefs.currentBrief
);

export const getBriefId = createSelector(
    [getBrief],
    currentBrief => currentBrief.briefId
);

export const getError = createSelector(
    [selectBriefs],
    briefs => briefs.error
);

export const getFilters = createSelector(
    [selectBriefs],
    briefs => briefs.filters
);

export const getVisibilityFilter = createSelector(
    [getFilters],
    filters => filters.visibility
);

export const getBriefTypeFilter = createSelector(
    [getFilters],
    filters => filters.briefTypes
);

export const getBriefMarketsFilter = createSelector(
    [getFilters],
    filters => filters.markets
);

export const getBriefStatesFilter = createSelector(
    [getFilters],
    filters => filters.states
);

export const getBriefLiveDateFilter = createSelector(
    [getFilters],
    filters => filters.liveDate
);

export const getBriefSearchFilter = createSelector(
    [getFilters],
    filters => filters.keywords  
);

export const getBriefSortByFilter = createSelector(
    [getFilters],
    filters => filters.sortBy
);

export const getBriefCreatorsFilter = createSelector(
    [getFilters],
    filters => filters.creators
);

export const getBriefAssigneesFilter = createSelector(
    [getFilters],
    filters => filters.assignees
);

export const getBriefGroupsFilter = createSelector(
    [getFilters],
    filters => filters.groups
);

export const getInfiniteScroll = createSelector(
    [selectBriefs],
    briefs => briefs.infiniteScroll
);

export const getNextBriefsFetching = createSelector(
    [getInfiniteScroll],
    infiniteScroll => infiniteScroll.fetching
);

export const getNextBriefsError = createSelector(
    [getInfiniteScroll],
    infiniteScroll => infiniteScroll.error
);

export const getComments = createSelector(
    [selectBriefs],
    briefs => briefs.comments
)

export const getTitle = createSelector(
    [selectBriefs],
    briefs => briefs.title
);

export const getUpdateBriefTitleFetching = createSelector(
    [getTitle],
    title => title.fetching
);

export const getUpdateBriefTitleError = createSelector(
    [getTitle],
    title => title.error
);

export const getStates = createSelector(
    [getBrief],
    currentBrief => currentBrief !== null ? currentBrief.states : null
);

export const getStatesId = createSelector(
    [getStates],
    states => states !== null ? states.map(x=>x.stateId) : null
);

export const getSortedStates = createSelector(
    [getStates],
    states => {
        if(states !== null) {
            return states.sort((a, b) => a.stateId - b.stateId)
        }
        return null;
    } 
);

export const getStatesList = createSelector(
    [getSortedStates, formFieldsSelectors.getSortedBriefsStates, getBrief],
    (sortedStates, sortedFormFieldsBriefsStates, currentBrief) => {
        // current represents the current category we process in the loop
        let current = null;
        // index represents the number of "process" state found
        let index = 0;

        // sortedStates is an array containing the activeState of the brief, sorted by stateOrder
        // sortedFormFieldsBriefsStates is an array containing all the states, sorted by stateOrder
        if(sortedStates === null || sortedFormFieldsBriefsStates === null || currentBrief === null) {
            return [];
        }
        
        // Filter to get only thes states of the workflow corresponding to the current brief type
        sortedFormFieldsBriefsStates = sortedFormFieldsBriefsStates.filter(s => s.briefType === currentBrief.typeId)

        // If brief is not active, the brief must be closed, so all the state are finished
        if (!currentBrief.briefActive) {
            return sortedFormFieldsBriefsStates.map(state => {
                state.status = 'finish';
                return state;
            }).filter(s=>!s.onlyShowIfActive)
        }

        // THE LOOP
        return sortedFormFieldsBriefsStates.reduce((acc, state) => {
            //Change current category 
            if (state.stateCategoryId !== current) {
                current = null;
            }

            // Loop over the states array and for each one :
            // if no "process" has beeen found yet, and the state is active
            if (index < sortedStates.length && sortedStates[index].stateId === state.stateId) {
                // mark the state as "process"
                state.status = 'process';
                // define the current category
                current = state.stateCategoryId;
                index++;
            // if found a category and if we didn't find all the active states yet, it means that this state is finished
            } else if(!current && index < sortedStates.length) {
                state.status = 'finish';
            // else, it means this state is waiting
            } else {
                state.status = 'wait';
            }
            
            //Add state to state list
            if (!(state.onlyShowIfActive && state.status !== 'process'))
                acc.push(state)

            return acc
        }, []);
    }
);

export const getWorkflow = createSelector(
    [getStatesList, formFieldsSelectors.getCategories, getBrief],
    (statesList, statesCategories, currentBrief) => {

        if(statesList === null || !statesList.length || statesCategories === null || currentBrief === null) {
            return [];
        }

        // Analyse the logs, get only entries regarding states updates
        let categoriesVisited = currentBrief.logs.filter(l=>l.updateField === "states").reverse().reduce((acc, curr) => {
            // Find the state corresponding to the update
            let s = statesList.find(s=>s.stateId === JSON.parse(curr.updateValues.after))
            if (s) {
                // Find the category of the state
                let catId = s.stateCategoryId
                let maxCatId = Math.max(...acc)
                // Since the logs are in chronological order, if we find a category superior to the one in the update, we remove it from the history of category visited, because it means the brief was sent back
                if (catId < maxCatId) 
                    acc = acc.filter(id => id !== maxCatId)
                // Add the category to our categoriesVisited array
                if (!acc.includes(catId))
                    acc.push(catId)
            }
            return acc
        }, [1]).sort((a, b) => a - b)
        // This function helped us determine if there are some categories in the workflow the brief never went through
        
        return statesList.reduce((acc, curr) => {
            // if current state is in a category after the one active, of if the brief already went through that category
            if(curr.stateCategoryId >= Math.max(...categoriesVisited) || categoriesVisited.includes(curr.stateCategoryId)) {

                // group states by category
                if (!acc[curr.stateCategoryId]) {
                    let category =  statesCategories.find(cat => cat.categoryId === curr.stateCategoryId);
                    acc[curr.stateCategoryId] = {
                        substeps: [],
                        status: '',
                        ...category
                    }
                }
                acc[curr.stateCategoryId].substeps.push(curr)

                // category takes the status of current state, unless it is already defines as "process"
                if (acc[curr.stateCategoryId].status !== 'process'){
                    acc[curr.stateCategoryId].status = curr.status;
                }
            }
            return acc;
        }, []);
    }
);

export const getAssignees = createSelector(
    [getBrief],
    currentBrief => currentBrief !== null ? currentBrief.assignees : null
);

export const getAssigneeWebdesigner = createSelector(
    [getAssignees],
    assignees => {
        const webdesigners = assignees !== null ? assignees.filter(assignee => assignee.asWebdesigner === 1) : null;

        if(webdesigners === null) {
            return null;
        }

        return webdesigners.length > 0 ? webdesigners[0] : null;
    }
);

export const getAssigneeIntegrator = createSelector(
    [getAssignees],
    assignees => {
        const integrators = assignees !== null ? assignees.filter(assignee => assignee.asIntegrator === 1) : null;

        if(integrators === null) {
            return null;
        }

        return integrators.length > 0 ? integrators[0] : null;
    }
);

export const getAssigneesId = createSelector(
    [getAssignees],
    assignees => assignees !== null ? assignees.map(x=>x.userId) : null
);

export const getCanPassStep = createSelector(
    [getStatesId, getAssigneesId, authSelectors.getPermissions, authSelectors.getAccount],
    (briefstates, briefassignees, permissions, account) => {
        if(briefstates === null || briefassignees === null || permissions === null || account === null) {
            return null;
        }

        return(
            (isArray(permissions.brief_canPassStep) ? 
                permissions.brief_canPassStep.some(r=>briefstates.includes(r)) 
            : 
                permissions.brief_canPassStep)
        || 
            ((isArray(permissions.brief_canPassStepAssigned) ? 
                permissions.brief_canPassStepAssigned.some(r=>briefstates.includes(r)) 
            : 
                permissions.brief_canPassStepAssigned) 
                && briefassignees.includes(account.userId))
        );
    }
)