// TODO - Handle CSRF
// TODO - Add Unit Tests
// TODO - Optimize some requests on the API (example : all results, ...)
// TODO - Normalize Store
// TODO - Add TypeScript

import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import MainRouter from './routes';
import authService from './services/authService';
import './index.scss';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();

authService.tokenLogin(store);

function App() {
    return(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div className={'router-container'}>
                    <MainRouter />
                    <ToastContainer />
                </div>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;