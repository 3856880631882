import * as types from '../../constants/actionTypes/formFields';

const initialState = {
    markets: null,
    weights: null,
    states: null,
    briefTypes: null,
    stateTypes: null,
    userStatus: null,
    platforms: null,
    sortBy: null,
    fetching: false,
    error: null,
    myStates: null,
    myMarkets: null
};

function formFields(state = initialState, action) {

    switch(action.type) {
        case types.LOAD_FORM_FIELDS_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_FORM_FIELDS_SUCCESS:
            // console.log(action.formFields.myStates)
            return { 
                ...state, 
                fetching: false, 
                markets: action.formFields.markets,
                weights: action.formFields.weights,
                states: action.formFields.states,
                briefTypes: action.formFields.briefTypes,
                stateCategories: action.formFields.stateCategories,
                stateTypes: action.formFields.stateTypes,
                userStatus: action.formFields.userStatus,
                platforms: action.formFields.platforms,
                sortBy: action.formFields.sortBy,
                myStates: action.formFields.myStates,
                myMarkets: action.formFields.myMarkets,
                error: null
            };
        
        case types.LOAD_FORM_FIELDS_FAILURE:
            return { 
                ...state, 
                fetching: false, 
                markets: null,
                weights: null,
                states: null,
                briefTypes: null,
                stateTypes: null,
                userStatus: null,
                platforms: null,
                sortBy: null,
                myStates: null,
                myMarkets: null,
                error: action.error
            };

        case types.LOAD_MARKETS_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_MARKETS_SUCCESS:
            return { ...state, fetching: false, markets: action.markets };

        case types.LOAD_MARKETS_FAILURE:
            return { ...state, fetching: false, markets: null, error: action.error };

        default:
            return state;
    }
}

export default formFields;