export const LOAD_ALL_GROUPS_REQUEST = 'LOAD_ALL_GROUPS_REQUEST';
export const LOAD_ALL_GROUPS_SUCCESS = 'LOAD_ALL_GROUPS_SUCCESS';
export const LOAD_ALL_GROUPS_FAILURE = 'LOAD_ALL_GROUPS_FAILURE';
export const LOAD_GROUP_REQUEST = 'LOAD_GROUP_REQUEST';
export const LOAD_GROUP_SUCCESS = 'LOAD_GROUP_SUCCESS';
export const LOAD_GROUP_FAILURE = 'LOAD_GROUP_FAILURE';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';