import * as types from '../../constants/actionTypes/briefs';
import * as visibilityFilters from '../../constants/briefsVisibilityFilter';


const initialState = {
    allBriefs: {
        items: null,
        total: 0
    },
    infiniteScroll: {
        cursor: null,
        fetching: false,
        error: null
    },
    filters: {
        visibility: visibilityFilters.SHOW_ALL_BRIEFS,
        briefTypes: [],
        markets: [],
        states: [],
        liveDate: null,
        keywords: '',
        sortBy: visibilityFilters.SORT_BY_LIVE_DATE,
        creators: [],
        assignees: []
    },
    currentBrief: null,
    requests: {
        brief: {
            fetching: false,
            error: null
        },
        infiniteScroll: {
            cursor: null,
            fetching: false,
            error: null
        },
        title: {
            fetching: false,
            error: null
        },
        description: {
            fetching: false,
            error: null
        },
        liveDate: {
            fetching: false,
            error: null
        },
        states: {
            fetching: false,
            error: null
        },
        webdesignerAssignment: {
            fetching: false,
            error: null
        },
        integratorAssignment: {
            fetching: false,
            error: null
        },
        weight: {
            fetching: false,
            error: null
        },
        platforms: {
            fetching: false,
            error: null
        },
        comments: {
            fetching: false,
            error: null
        },
        markets: {
            fetching: false,
            error: null
        },
        files: {
            fetching: false,
            error: null
        },
        closeBrief: {
            fetching: false,
            error: null
        },
        documents: {
            fetching: false,
            error: null
        },
    },
    title: {
        fetching: false,
        error: null
    },
    description: {
        fetching: false,
        error: null
    },
    comments: {
        fetching: false,
        error: null
    },
    files: {
        fetching: false,
        error: null
    },
    fetching: false,
    error: null
};


function briefs(state = initialState, action) {

    switch(action.type) {
        case types.CREATE_BRIEF_REQUEST:
            return { ...state, fetching: true, error: null};

        case types.CREATE_BRIEF_SUCCESS:
            return { ...state, fetching: false };

        case types.CREATE_BRIEF_FAILURE:
            return { ...state, fetching: false, error: action.error};

        case types.LOAD_ALL_BRIEFS_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_ALL_BRIEFS_SUCCESS:
            return { ...state, fetching: false, allBriefs: action.briefs }

        case types.LOAD_ALL_BRIEFS_FAILURE:
            return { ...state, fetching: false, allBriefs: {
                    items: null,
                    total: 0
                }, error: action.error };

        case types.LOAD_NEXT_BRIEFS_REQUEST:
            return { ...state, infiniteScroll: {
                        ...state.infiniteScroll,
                        fetching: true,
                        cursor: action.cursor,
                        error: null
                    }
                };
        
        case types.LOAD_NEXT_BRIEFS_SUCCESS:
            return { ...state, allBriefs: {
                        ...state.allBriefs,
                        items: [...state.allBriefs.items, ...action.nextBriefs]
                    },
                    infiniteScroll: {
                        ...state.infiniteScroll,
                        fetching: false
                    }
                };

        case types.LOAD_NEXT_BRIEFS_FAILURE:
            return { ...state, infiniteScroll: {
                    ...state.infiniteScroll,
                    fetching: false,
                    error: action.error
                }
            };

        case types.LOAD_BRIEF_REQUEST:
            return { ...state, currentBrief: null, fetching: true, error: null };

        case types.LOAD_BRIEF_SUCCESS:
            return { ...state, fetching: false, currentBrief: action.brief };

        case types.LOAD_BRIEF_FAILURE:
            return { ...state, fetching: false, currentBrief: null, error: action.error };

        case types.UPDATE_STATE_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.UPDATE_STATE_SUCCESS:
            return { ...state, 
                    currentBrief: {
                        ...state.currentBrief,
                        briefActive: action.briefActive,
                        states: action.states,
                        logs: [action.log, ...state.currentBrief.logs]
                    },
                    requests: {
                        ...state.requests,
                        states: {
                            fetching: false
                        }
                    }
            };

        case types.UPDATE_STATE_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.PARALLELIZE_STATE_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.PARALLELIZE_STATE_SUCCESS:
            return { ...state, 
                    currentBrief: {
                        ...state.currentBrief,
                        states: action.states,
                        logs: [action.log, ...state.currentBrief.logs]
                    },
                    requests: {
                        ...state.requests,
                        states: {
                            fetching: false
                        }
                    }
            };

        case types.PARALLELIZE_STATE_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.UNPARALLELIZE_STATE_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.UNPARALLELIZE_STATE_SUCCESS:
            return { ...state, 
                    currentBrief: {
                        ...state.currentBrief,
                        states: action.states,
                        logs: [action.log, ...state.currentBrief.logs]
                    },
                    requests: {
                        ...state.requests,
                        states: {
                            fetching: false
                        }
                    }
            };

        case types.UNPARALLELIZE_STATE_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    states: {
                        fetching: false,
                        error: action.error
                    }
                }
            };


        case types.ADD_COMMENT_REQUEST:
            return { ...state, comments: {
                    fetching: true,
                    error: null
                }
            };

        case types.ADD_COMMENT_SUCCESS:
            return { ...state, 
                currentBrief: {
                    ...state.currentBrief,
                    logs: [action.comment, ...state.currentBrief.logs]
                },
                comments: {
                    fetching: false
                }
            };

        case types.ADD_COMMENT_FAILURE:
            return { ...state, comments: {
                    fetching: false,
                    error: action.error
                }
            };

        case types.EDIT_BRIEF_TITLE_REQUEST:
            return { ...state, title: {
                fetching: true,
                error: null
            }};

        case types.EDIT_BRIEF_TITLE_SUCCESS:
            return { ...state, title: {
                    fetching: false
                },
                currentBrief: {
                    ...state.currentBrief,
                    briefTitle: action.title,
                    logs: [action.log, ...state.currentBrief.logs]
                }
            };

        case types.EDIT_BRIEF_TITLE_FAILURE:
            return { ...state, title: {
                fetching: false,
                error: action.error
            }};

        case types.EDIT_BRIEF_DESCRIPTION_REQUEST:
            return { ...state, description: {
                fetching: true,
                error: null
            }};

        case types.EDIT_BRIEF_DESCRIPTION_SUCCESS:
            return { ...state, description: {
                    fetching: false
                },
                currentBrief: {
                    ...state.currentBrief,
                    briefDesc: action.description,
                    logs: [action.log, ...state.currentBrief.logs]
                }
            };

        case types.EDIT_BRIEF_DESCRIPTION_FAILURE:
            return { ...state, description: {
                fetching: false,
                error: action.error
            }};

        case types.CHANGE_LIVE_DATE_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    liveDate: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.CHANGE_LIVE_DATE_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    briefLiveDate: action.liveDate,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    liveDate: {
                        fetching: false
                    }
                }
            }; 

        case types.CHANGE_LIVE_DATE_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    liveDate: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.ADD_BRIEF_FILE_REQUEST:
            return { ...state, files: {
                        fetching: true,
                        error: null
                    }
                };

        case types.ADD_BRIEF_FILE_SUCCESS:
            return { ...state, currentBrief: {
                        ...state.currentBrief,
                        documents: [...state.currentBrief.documents, action.file],
                        logs: [action.file, ...state.currentBrief.logs]
                    },
                    files: {
                        fetching: false
                    }
                };

        case types.ADD_BRIEF_FILE_FAILURE:
            return { ...state, files: {
                        fetching: false,
                        error: action.error
                    }
                };

        case types.CHANGE_WEBDESIGNER_ASSIGNMENT_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    webdesignerAssignment: {
                        fetching: true,
                        error: null
                    }
                }
            }

        case types.CHANGE_WEBDESIGNER_ASSIGNMENT_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    assignees: action.newAssignees,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    webdesignerAssignment: {
                        fetching: false
                    }
                }
            }

        case types.CHANGE_WEBDESIGNER_ASSIGNMENT_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    webdesignerAssignment: {
                        fetching: false,
                        error: action.error
                    }
                }
            }

        case types.CHANGE_INTEGRATOR_ASSIGNMENT_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    integratorAssignment: {
                        fetching: true,
                        error: null
                    }
                }
            }

        case types.CHANGE_INTEGRATOR_ASSIGNMENT_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    assignees: action.newAssignees,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    integratorAssignment: {
                        fetching: false
                    }
                }
            }

        case types.CHANGE_INTEGRATOR_ASSIGNMENT_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    integratorAssignment: {
                        fetching: false,
                        error: action.error
                    }
                }
            }

        case types.CHANGE_WEIGHT_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    weight: {
                        fetching: true,
                        error: null
                    }
                }
            }

        case types.CHANGE_WEIGHT_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    weightId: action.weight.value,
                    weightName: action.weight.label,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    weight: {
                        fetching: false
                    }
                }
            }

        case types.CHANGE_WEIGHT_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    weight: {
                        fetching: false,
                        error: action.error
                    }
                }
            }

        case types.CHANGE_PLATFORMS_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    platforms: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.CHANGE_PLATFORMS_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    platforms: action.platforms,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    platforms: {
                        fetching: false
                    }
                }
            }; 

        case types.CHANGE_PLATFORMS_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    platforms: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.CHANGE_MARKETS_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    markets: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.CHANGE_MARKETS_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    markets: action.markets,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    markets: {
                        fetching: false
                    }
                }
            };

        case types.CHANGE_MARKETS_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    markets: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.TOGGLE_CLOSE_BRIEF_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    closeBrief: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.TOGGLE_CLOSE_BRIEF_SUCCESS:
            return { ...state, currentBrief: {
                    ...state.currentBrief,
                    briefActive: action.status,
                    logs: [action.log, ...state.currentBrief.logs]
                },
                requests: {
                    ...state.requests,
                    closeBrief: {
                        fetching: false
                    }
                }
            };

        case types.TOGGLE_CLOSE_BRIEF_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    closeBrief: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.CHANGE_BRIEFS_VISIBILITY_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    visibility: action.visibilityFilter
                }
            };

        case types.CHANGE_BRIEF_TYPES_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    briefTypes: action.briefTypes
                }
            };

        case types.CHANGE_BRIEF_MARKETS_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    markets: action.markets
                }
            };

        case types.CHANGE_BRIEF_STATES_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    states: action.states
                }
            };

        case types.CHANGE_BRIEF_CREATORS_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    creators: action.creators
                }
            };

        case types.CHANGE_BRIEF_ASSIGNEES_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    assignees: action.assignees
                }
            };

        case types.CHANGE_BRIEF_GROUPS_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    groups: action.groups
                }
            };

        case types.CHANGE_BRIEF_LIVE_DATE_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    liveDate: action.liveDate
                }
            };

        case types.CHANGE_BRIEF_SEARCH_FILTER:
            return { ...state, filters: {
                    ...state.filters,
                    keywords: action.keywords
                }
            };

        case types.CHANGE_BRIEF_SORT:
            return { ...state, filters: {
                    ...state.filters,
                    sortBy: action.sortBy
                }
            };

        case types.RESET_FILTERS:
            return { ...state, filters: initialState.filters };

        case types.UPDATE_BRIEF_IN_LIST:
            return { ...state, fetching: false, allBriefs: {
                ...state.allBriefs,
                items: state.allBriefs.items.map((brief)=>{
                    if(action.brief.briefId === brief.briefId){
                        return action.brief;
                    }else{
                        return brief;
                    }
                })
            }, error: action.error };

        // case types.DELETE_FILE_SUCCESS:
        //     return { ...state, currentBrief: {
        //         ...state.currentBrief,
        //         documents: state.currentBrief.documents.map(doc=>{
        //             let obseleteDoc = doc;
        //             if(obseleteDoc.documentId === action.fileId){
        //                 obseleteDoc.documentIsObsolet = 1;
        //             }
        //             return obseleteDoc;
        //         })
        //     }}

        // case types.ENABLE_FILE_SUCCESS:
        //     return { ...state, currentBrief: {
        //         ...state.currentBrief,
        //         documents: state.currentBrief.documents.map(doc=>{
        //             let obseleteDoc = doc;
        //             if(obseleteDoc.documentId === action.fileId){
        //                 obseleteDoc.documentIsObsolet = 0;
        //             }
        //             return obseleteDoc;
        //         })
        //     }}
        case types.DELETE_FILE_SUCCESS:
            return { ...state, currentBrief: {
                ...state.currentBrief,
                logs: [action.log, ...state.currentBrief.logs]
            }}

        case types.ENABLE_FILE_SUCCESS:
            return { ...state, currentBrief: {
                ...state.currentBrief,
                logs: [action.log, ...state.currentBrief.logs]
            }}


        case types.MODIFY_FILES_REQUEST:
            return { ...state, requests: {
                ...state.requests,
                documents: {
                    fetching: true,
                    error: null
                }
            }};

        case types.MODIFY_FILES_SUCCESS:
            return { 
                ...state, 
                currentBrief: {
                    ...state.currentBrief,
                    documents: state.currentBrief.documents.map(doc=>{
                        let updatedDoc = action.docs.find(x=>doc.documentId === x.documentId)
                        if(updatedDoc){
                            doc.documentIsObsolet = updatedDoc.modifType === types.DELETE_FILE_REQUEST ? 1 : 0;
                        }
                        return doc;
                    })
                },
                requests: {...state.requests, documents: {
                    fetching: false,
                    error: null
                }}
            }

        case types.MODIFY_FILES_FAILURE:
            return { ...state, requests: {
                ...state.requests,
                documents: {
                    fetching: false,
                    error: action.error
                }
            }};
                
        default:
            return state;
    }
}

export default briefs;