import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as types from '../constants/actionTypes/auth';
import * as selectors from '../selectors/auth';
import Loader from '../components/Loader';

function PrivateRoute({ token, layout: Layout, component, location, checkAccess, ...props}) {

    const Component = component;

    useEffect(() => {
        checkAccess(token);
    }, [checkAccess, token]);


    /**
     * Check if a Layout is defined
     */
    switch(typeof(Layout)) {
        
        case 'function':
            return(
                <Layout component={component} {...props} />
            );

        default:
            return(
                <Route
                    {...props}
                    render={ () => 
                        <Suspense fallback={<Loader/>}>
                            <Component {...props} />
                        </Suspense>
                    }
                />
            );
    }

}

PrivateRoute.propTypes = {
    token: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired
};

const mapStateToProps = (state) => ({
    token: selectors.getToken(state)
});

const mapDispatchToProps = (dispatch) => ({
    checkAccess: (token) => dispatch({
        type: types.CHECK_ACCESS,
        token: token
    })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRoute);