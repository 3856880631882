import * as types from '../../constants/actionTypes/groups';

const initialState = {
    allGroups: null,
    currentGroup: null,
    requests: {
        loadAllGroups: {
            fetching: false,
            error: null
        },
        loadGroup: {
            fetching: false,
            error: null
        },
        createGroup: {
            fetching: false,
            error: null
        }
    },
    fetching: false,
    error: null
};

function groups(state = initialState, action) {

    switch(action.type) {
        case types.LOAD_ALL_GROUPS_REQUEST:
            return { ...state, fetching: true, error: null, allGroups: [] };

        case types.LOAD_ALL_GROUPS_SUCCESS:
            return { ...state, fetching: false, allGroups: action.groups };

        case types.LOAD_ALL_GROUPS_FAILURE:
            return { ...state, fetching: false, allGroups: null, error: action.error };

        case types.LOAD_GROUP_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_GROUP_SUCCESS:
            return { ...state, fetching: false, currentGroup: action.group };

        case types.LOAD_GROUP_FAILURE:
            return { ...state, fetching: false, currentGroup: null, error: action.error };

        case types.CREATE_GROUP_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    createGroup: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.CREATE_GROUP_SUCCESS:
            return { ...state, 
                allGroups: [...state.allGroups, action.group],
                requests: {
                    ...state.requests,
                    createGroup: {
                        fetching: false
                    }
                }
            };

        case types.CREATE_GROUP_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    createGroup: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        case types.UPDATE_GROUP_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    updateGroup: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.UPDATE_GROUP_SUCCESS:
            return { ...state, 
                requests: {
                    ...state.requests,
                    updateGroup: {
                        fetching: false,
                        error: null
                    }
                }
            };

        case types.UPDATE_GROUP_FAILURE:
            return { ...state, 
                requests: {
                    ...state.requests,
                    updateGroup: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        default:
            return state;
    }
}

export default groups;