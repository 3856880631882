import * as types from '../../constants/actionTypes/search';

const initialState = {
    results: {
        items: null,
        total: 0
    },
    requests: {
        search: {
            fetching: false,
            error: null
        },
        nextResults: {
            fetching: false,
            error: null
        }
    },
    fetching: false,
    error: null
}

function search(state = initialState, action) {

    switch(action.type) {

        case types.SEARCH_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.SEARCH_SUCCESS:
            return { ...state, fetching: false, results: action.results };

        case types.SEARCH_FAILURE:
            return { ...state, fetching: false, results: {
                    items: null,
                    total: 0
                },
                error: action.error 
            }

        case types.LOAD_NEXT_SEARCH_RESULTS_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    nextResults: {
                        fetching: true,
                        error: null
                    }
                }
            };

        case types.LOAD_NEXT_SEARCH_RESULTS_SUCCESS:
            return { ...state,
                results: {
                    ...state.results,
                    items: [...state.results.items, ...action.results]
                },
                requests: {
                    ...state.requests,
                    nextResults: {
                        fetching: false
                    }
                }
            };
        
        case types.LOAD_NEXT_SEARCH_RESULTS_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    nextResults: {
                        fetching: false,
                        error: action.error
                    }
                }
            };

        default:
            return state;
    }
}

export default search;