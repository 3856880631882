import React from 'react';

function Error() {
    return(
        <>
            <h1>Error</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget ligula pellentesque, sodales ipsum et, accumsan arcu. Suspendisse in risus ut libero imperdiet iaculis et vitae metus.
            </p>
            {/* <img src="" alt="Error"> */}
        </>
    );
}

export default Error;