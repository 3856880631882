import React, { Suspense, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import styles from './scss/layout.module.scss';
import ReactDOM from 'react-dom';
import Sidenav from '../components/Sidenav';
import Loader from '../components/Loader';

function DefaultLayout({ component: MatchedPage, sidenavContainer, ...props }) {

    const style = {
		height: props.height
    }

    useEffect(() => {
        setSidenav(sidenavContainer)
    }, [sidenavContainer])

    const [sidenav, setSidenav] = useState(false)

    const SidenavInPortal = () => {
        if(!!sidenav.current) {
            return ReactDOM.createPortal(
                <Sidenav />,
                sidenav.current
            )
        }
        return null
    }

    return(
        <>
            {!!sidenav.current &&
                <SidenavInPortal />
            }
            <Route {...props} render={matchProps => (
                <div className={styles.layout} style={style}>
                    <main>
                        <Suspense fallback={<Loader/>}>
                            <MatchedPage {...matchProps} />
                        </Suspense>
                    </main>
                </div>
            )} />
        </>
    );
}

export default DefaultLayout;