import { useState, useEffect } from 'react';

function useBodyHeightResizer() {
    const [height, setHeight] = useState(Math.max(document.documentElement.clientHeight, document.body.scrollHeight, window.innerHeight || 0));

    useEffect(() => {
		const handleResize = () => {
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			const newHeight = viewportHeight >= document.body.scrollHeight ?  viewportHeight : 'auto';

			setHeight(newHeight);
		}

		// window.onhashchange = handleResize;

		// window.addEventListener('load', handleResize);
		// window.addEventListener('resize', handleResize);

		return () => {
			// window.removeEventListener('load', handleResize);
			// window.removeEventListener('resize', handleResize);
		};
	}, []);

    return '100vh';
}

export default useBodyHeightResizer;