export const LOAD_FORM_FIELDS_REQUEST = 'LOAD_FORM_FIELDS_REQUEST';
export const LOAD_FORM_FIELDS_SUCCESS = 'LOAD_FORM_FIELDS_SUCCESS';
export const LOAD_FORM_FIELDS_FAILURE = 'LOAD_FORM_FIELDS_FAILURE';
export const LOAD_MARKETS_REQUEST = 'LOAD_MARKETS_REQUEST';
export const LOAD_MARKETS_SUCCESS = 'LOAD_MARKETS_SUCCESS';
export const LOAD_MARKETS_FAILURE = 'LOAD_MARKETS_FAILURE';
export const LOAD_BRIEFS_TYPES_REQUEST = 'LOAD_BRIEFS_TYPES_REQUEST';
export const LOAD_BRIEFS_TYPES_SUCCESS = 'LOAD_BRIEFS_TYPES_SUCCESS';
export const LOAD_BRIEFS_TYPES_FAILURE = 'LOAD_BRIEFS_TYPES_FAILURE';
export const LOAD_WEIGHTS_REQUEST = 'LOAD_WEIGHTS_REQUEST';
export const LOAD_WEIGHTS_SUCCESS = 'LOAD_WEIGHTS_SUCCESS';
export const LOAD_WEIGHTS_FAILURE = 'LOAD_WEIGHTS_FAILURE';