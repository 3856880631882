import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import briefs from './briefs';
import formFields from './formFields';
import search from './search';
import groups from './groups';
import users from './users';
import statistics from './stats';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    briefs,
    formFields,
    search,
    groups,
    users,
    statistics
});