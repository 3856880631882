import React from 'react';
import { Link } from 'react-router-dom';
import styles from './scss/item.module.scss';
import ChipsList from '../Chips/ChipsList';
import moment from 'moment';
import * as selectors from '../../selectors/auth';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';

function Item({small= false, currentUser, assignToMe, ...props}) {

    // Fix component error on dashboard
    if(!currentUser){
        return <></>
    }

    let className = styles.item;
    if (small)
        className += ' ' + styles.itemSmall

    let assignedWebDesigner = props.brief.assignees.find(assignee=>(assignee.asWebdesigner === 1));
    let assignedIntegrator = props.brief.assignees.find(assignee=>(assignee.asIntegrator === 1));
    let allSignees = props.brief.assignees.map(assignee=>assignee.userName);

    // Check integrator and webdesigner assignees
    let assignees = {assignedWebDesigner: assignedWebDesigner || null, assignedIntegrator:assignedIntegrator || null};

    // Check if the brief HasWebDesignInProgressStatus
    let briefHasWebDesignInProgressStatus = props.brief.states.filter(brief=>(brief.stateId === 6)).length>0;

    // Check the Role of the current user
    let imAWebDesigner = currentUser.groups.filter(group=>(group.groupId === 3)).length>0 ? 1:0;
    let imAnIntegrator = currentUser.groups.filter(group=>(group.groupId === 2)).length>0 ? 1:0;

    let showAssignToMe = () => {
        // If user is integrateur AND brief has nos assignee as integrateur : show assign to mr to the user
        // Action here should be assigned as integrateur
        if((assignees.assignedIntegrator === null) && (imAnIntegrator === 1)){
            return true;
        }

        // // If user is webdesigner AND brief has no assignee as webdesigner AND brief is sent 
        // // to web design [STATE_ID should be 6 for web design in progress] : show the assign to me to the user
        // // Action here should be assigned as webdesigner
        if(briefHasWebDesignInProgressStatus && (assignees.assignedWebDesigner === null) && (imAWebDesigner === 1)){
            return true;
        }
        
        return false;
    }

    let creation_date = moment(props.brief.created_at);
    creation_date = creation_date.add(creation_date.utcOffset(), 'm');

    return(
        <Link to={'/brief/' + props.brief.briefId} className={className}>
            <article>
                <div className={styles.pillsCtn}>
                    {props.brief.priority && props.brief.priority > 1 &&
                        <div className={styles.pill + ' ' + styles[`priority-${props.brief.priority}`]}>
                            {props.brief.priority === 3 && 'High'}
                            {props.brief.priority === 2 && 'Medium'}
                        </div>
                    }

                    {props.brief.weightName && props.brief.weightValue === 5 &&
                        <div className={styles.pill + ' ' + styles.urgent}>
                            {props.brief.weightName}
                        </div>
                    }
                </div>

                <div className={styles.topInfoBar}>
                    
                    <h2>{props.brief.briefTitle}</h2> 

                    {!!props.brief.states &&
                        <div className={styles.state}>
                            <div className={styles[`iconCircle_${props.brief.states[0].stateIcon}`]}>
                                {/*<img src={props.brief.states[0].stateIcon} alt={props.brief.states[0].stateName} />*/}
                            </div>
                            
                            {props.brief.states[0].stateName}
                        </div>
                    }

                    <div className={styles.liveDate}>
                        <p>{moment(props.brief.briefLiveDate).format('ddd, YYYY/MM/DD')}</p>
                    </div>
                </div>

                <div className={styles.bottomInfoBar}>
                    <div className={styles.infoLeft}>

                         {/*<div className={styles.infoType}>{props.brief.typeName}</div>*/}

                        <div className={styles.infoId}>
                            <img src={'/static/img/' + (props.brief.typeId === 1 ? 'brief' : 'offer') + '.svg'} alt={`${props.brief.typeName} brief`} title={`${props.brief.typeName} brief`} />
                            <span>#{props.brief.briefId}</span>
                        </div>

                        {!small &&
                            <>
                                <div className={styles.infoCreation}>
                                    Created by <span className={styles.strong}>{props.brief.owner.userName}</span> the <span className={styles.strong}>{creation_date.format('YYYY/MM/DD')}</span> at <span className={styles.strong}>{creation_date.format('HH:mm')}</span>
                                </div>

                                <div className={styles.marketsList}>
                                    {/* {props.brief.markets.map((market, i) => {
                                        return(
                                            <Chip key={`${market.marketSlug}-${i}`} value={market.marketSlug} />
                                        );
                                    })} */}
                                     <ChipsList 
                                        dropdownButtonLabel={'Add markets'}
                                        collection={props.brief.markets.map(market => market.marketSlug || market)} 
                                        editMode={false}
                                    />
                                </div>
                            </>
                        }
                        
                    </div>

                    <div className={styles.infoRight}>
                        {props.brief.weightName&&(
                            <label style={{fontWeight:'bold'}}>{props.brief.weightName}</label>
                        )}
                        {assignees.assignedIntegrator &&
                            <span><span>Integrator:&nbsp;</span><span className={styles.strong}>{assignees.assignedIntegrator.userName}</span></span>
                        }
                        {assignees.assignedWebDesigner &&
                            <span><span>Webdesigner:&nbsp;</span><span className={styles.strong}>{assignees.assignedWebDesigner.userName}</span></span>
                        }
                        {/* Show button assign to me when is not already assigned */}
                        {/* If no show assigned to person */}
                        {showAssignToMe() && props.brief.briefActive === 1 &&(
                            <button onClick={(e)=>{
                                e.preventDefault();
                                assignToMe(props.brief.briefId, imAWebDesigner,  imAnIntegrator);
                            }}>assign to me</button>
                        )}
                    </div>
                </div>

            </article>
        </Link>
    );
}


const mapStateToProps = (state) => ({
    currentUser: selectors.getAccount(state)
});

const mapDispatchToProps = (dispatch) => ({
    assignToMe: (briefId, asWebdesigner, asIntegrator) => dispatch({ type: types.ASSIGN_TO_ME_BRIEF_REQUEST, briefId: briefId, asIntegrator: asIntegrator, asWebdesigner: asWebdesigner})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Item);