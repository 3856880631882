import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes/users';
import axios from 'axios';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function removeUser(token, userId){
    return axios({
        method: 'post',
        url: `${API_URI}/removeUser`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {userId: userId}
    });
}

function loadAllUsers(token, all = false, keyword = null, groups = null) {
    let params = {}
    if(all){
        params.all = all;
    }

    if(keyword){
        params.keyword = keyword;
    }

    if(groups){
        params.groups = groups;
    }
    
    return axios({
        method: 'get',
        url: `${API_URI}/getUsersList`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: params
    });
}

function loadUser(id, token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getUser`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            userId: id
        }
    });
}


function inviteUser(email, groups, token, action) {
    return axios({
        method: 'post',
        url: `${API_URI}/${action}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            email: email,
            groups: groups
        }
    });
}

function updateUser(user, token){

    let params = {
        email:user.userEmail,
        name:user.userName,
        userId: user.userId,
        groups: user.groups.map(group => group.groupId),
        statusId: user.statusId
    };
    
    if(user.newPassword === user.confirmNewPassword){
        params.password = user.newPassword;
        params.password_confirmation = user.confirmNewPassword;
    }

    return axios({
        method: 'post',
        url: `${API_URI}/updateUser`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: params
    });
}

function updateMe(user, token){

    let params = {
        email:user.userEmail,
        name:user.userName
    };
    
    if(user.newPassword && (user.newPassword === user.confirmNewPassword) && user.newPassword.length > 8){
        params.new_password = user.newPassword;
        params.password_confirmation = user.confirmNewPassword;
    }

    return axios({
        method: 'post',
        url: `${API_URI}/updateMe`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: params
    });
}

export function* workerRemoveUser(payload){
    try{
        const token = yield select(getToken);
        const response = yield call(removeUser, token, payload.userId);
        yield put({ type: types.LOAD_ALL_USERS_REQUEST, all: true  });
    }catch(error){
        yield put({ type: types.REMOVE_USER_FAILURE, error });
    }
}

export function* workerLoadAllUsers(payload) {
    try{
        let keyword = payload.keyword || null;
        let all = payload.all;
        let groups = payload.groups;

        const token = yield select(getToken);
        const response = yield call(loadAllUsers, token, all, keyword, groups);
        const users = response.data;

        yield put({ type: types.LOAD_ALL_USERS_SUCCESS, users });

    } catch (error) {
        yield put({ type: types.LOAD_ALL_USERS_FAILURE, error });
    }
}

export function* workerLoadUsers() {
    try{
        const token = yield select(getToken);
        const response = yield call(loadAllUsers, token);
        const users = response.data;

        yield put({ type: types.LOAD_ALL_USERS_SUCCESS, users });

    } catch (error) {
        yield put({ type: types.LOAD_ALL_USERS_FAILURE, error });
    }
}

export function* workerLoadUser(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(loadUser, payload.id, token);
        const user = response.data;

        yield put({ type: types.LOAD_USER_SUCCESS, user });

    } catch (error) {
        yield put({ type: types.LOAD_USER_FAILURE, error });
    }
}

export function* workerInviteUser(payload) {
    try{

        const token = yield select(getToken);
        yield call(inviteUser, payload.email, payload.groups, token, payload.action);

        yield put({ type: types.INVITE_USER_SUCCESS, invitedUser: payload.email });
        
        // Update all user when : TODO: To be improved for performance issue
        yield call(workerLoadAllUsers, {keyword:'', all:true});
        // keyword: keyword, all: all

    } catch (error) {
        console.log(error);
        yield put({ type: types.INVITE_USER_FAILURE, error });
    }
}

export function* workerUpdateUser(payload){
    try{
        const token = yield select(getToken);
        const response = yield call(updateUser, payload.user, token);
        const user = response.data[0];
        yield put({ type: types.UPDATE_USER_ADMIN_SUCCESS, user});
    }catch(error){
        console.log(error);
        yield put({ type: types.UPDATE_USER_ADMIN_FAILURE, error });
    }
}

export function* workerUpdateMe(payload){
    try{
        const token = yield select(getToken);
        const response = yield call(updateMe, payload.user, token);
        const user = response.data[0];
        yield put({ type: types.UPDATE_USER_ME_SUCCESS, user });
    }catch(error){
        console.log('Error payload.user', error);
        yield put({ type: types.UPDATE_USER_ME_FAILURE, error });
    }
}

export function* watcherUsers() {
    yield takeLatest(types.LOAD_USERS_REQUEST, workerLoadUsers);
    yield takeLatest(types.LOAD_ALL_USERS_REQUEST, workerLoadAllUsers);
    yield takeLatest(types.REMOVE_USER_REQUEST, workerRemoveUser);
    yield takeLatest(types.LOAD_USER_REQUEST, workerLoadUser);
    yield takeLatest(types.INVITE_USER_REQUEST, workerInviteUser);
    yield takeLatest(types.UPDATE_USER_ME_REQUEST, workerUpdateMe);
    yield takeLatest(types.UPDATE_USER_ADMIN_REQUEST, workerUpdateUser);
}