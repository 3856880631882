import * as types from '../constants/actionTypes/auth';

const authService = {

    addToken(token, tokenType, expiresAt) {
        localStorage.setItem('token', token);
    },
    removeToken() {
        localStorage.removeItem('token');
    },
    tokenLogin(store) {
        if(localStorage.getItem('token')) {
            store.dispatch({ type: types.TOKEN_LOGIN, token: localStorage.getItem('token') });
            store.dispatch({ type: types.GET_ACCOUNT_REQUEST });
        }
    }
};

export default authService;