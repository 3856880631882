import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './scss/sidenav.module.scss';
import * as authSelectors from '../../selectors/auth';
import { connect } from 'react-redux';
import Loader from '../Loader';

import logo1 from '../../assets/briefgenlogo_draft2_mini.png';
import logo2 from '../../assets/briefgenlogo_draft2_full.png';

function Sidenav({fetching, permissions, ...props}) {

    const [expanded, setExpanded] = useState(false)
    let width = styles.sidenavWidth;
    if(expanded) width += ' ' + styles.sidenavWidthExpanded

    return(
        <div className={styles.sidenav + ' ' + styles.sidenavWidth} onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
            <div className={styles.navContainer + ' ' + width}>
                <NavLink exact to="/" className={styles.btnLogo}>
                    <div className={styles.logo}>
                        <img src={expanded ? logo2 : logo1} alt="Briefgen Logo" />
                        {/*<h1><span>BRIEF</span><span>GEN</span></h1>*/}
                    </div>
                </NavLink>

                <nav>

                    {fetching || permissions === null ?
                        <Loader />
                    :
                        <>
                            {permissions.brief_canCreate && 
                                <div className={styles.btnContainer}>
                                    <NavLink to="/brief-creation" className={styles.btn_newBrief}>
                                        <div>
                                            <span>New Brief</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/offer-brief-creation"  className={styles.btn_newBriefOffer}>
                                        <div>
                                            <span>New Offer Brief</span>
                                        </div>
                                    </NavLink>
                                </div>
                            }
                            <div className={styles.linkContainer}>
                                <div>
                                    <NavLink exact to="/" className={styles.link_dashboard}>
                                        <span>Dashboard</span>
                                    </NavLink>                  
                                
                                    {permissions.page_seeUserList &&
                                        <NavLink to="/user-list" className={styles.link_userList}>
                                            <span>User List</span>
                                        </NavLink>                  
                                    }
                                
                                    {permissions.page_seeGroupList &&
                                        <NavLink to="/group-list" className={styles.link_groupList}>
                                            <span>Group List</span>
                                        </NavLink>  
                                    }

                                    {permissions.page_seeStatistics &&
                                        <NavLink to="/statistics" className={styles.link_statistics}>
                                            <span>Statistics</span>
                                        </NavLink> 
                                    }


                                </div>                
                            
                                <div>
                                    <NavLink to="/my-account" className={styles.link_account}>
                                        <span>My Account</span>
                                    </NavLink>                  
                                
                                    <NavLink to="/logout" className={styles.link_logout}>
                                        <span>Sign out</span>
                                    </NavLink>
                                </div>
                            </div>
                        </>
                    }
                </nav>
            </div>
           
        </div>
    );
}

const mapStateToProps = (state) => ({
    permissions: authSelectors.getPermissions(state),
    fetching: authSelectors.getFetching(state)
})


export default connect(
    mapStateToProps,
)(Sidenav);