import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../Helper/ErrorBoundary';
import { connect } from 'react-redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as selectors from '../../selectors/formFields';
import * as briefsSelectors from '../../selectors/briefs';
import * as groupsSelectors from '../../selectors/groups';
import * as usersSelectors from '../../selectors/users';
import Loader from '../Loader';
import './scss/select.module.scss';
import useClickOutside from './hooks';
import styles from './scss/select.module.scss';

function MultiSelectDropdown({fetching, formFields, filters, error, allGroups, users, assigneesList, groupsList, creatorsList, ...props}) {

    const [isOpen, setisOpen] = useState(false);

    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, (open)=>{
        setisOpen(open);
    });

    let options = [];
    let selectedItems = props.value || [];

    function initFilterDropdown(filter, item) {
        if(filter.includes(item.value) && props.isFilters) {
            selectedItems.push(item);
        }
    }

    switch(props.type) {
        case 'markets':
            if(formFields.myMarkets !== null) {
                for(const [i, market] of formFields.myMarkets.entries()) {
                    options.push({ label: market.marketSlug, value: market.marketId });
                    initFilterDropdown(filters.markets, options[i]);
                }
            }
        break;

        case 'weights':
            if(formFields.weights !== null) {
                options = [{value:-1,label:"Undefined"}, ...formFields.weights.map(weight => ({ label: weight.weightName, value: weight.weightId }))];
            }
        break;

        case 'states':
            if(formFields.myStates !== null) {
                for(const [i, state] of formFields.myStates.entries()) {
                    options.push({ label: state.stateName, value: state.possibleStates });
                    initFilterDropdown(filters.states, options[i]);
                }
            }
        break;

        case 'briefTypes':
            if(formFields.briefTypes !== null) {
                for(const [i, type] of formFields.briefTypes.entries()) {
                    options.push({ label: type.typeName, value: type.typeId });
                    initFilterDropdown(filters.briefTypes, options[i]);
                }
            }
        break;

        case 'sortBy':
            if(formFields.sortBy !== null) {
                for(const [i, sort] of formFields.sortBy.entries()) {
                    options.push({ label: sort, value: sort });
                    if(filters.sortBy === options[i].value && props.isFilters) {   
                        selectedItems.push(options[i]);
                    }
                }
            }
        break;

        case 'stateTypes':
            if(formFields.stateTypes !== null) {
                options = formFields.stateTypes.map(type => ({ label: type.stateTypeLabel, value: type.stateTypeId }));
            }
        break;

        case 'userStatus':
            if(formFields.userStatus !== null) {
                options = formFields.userStatus.map(status => ({ label: status.statusName, value: status.statusId }));
            }
        break;

        case 'platforms':
            if(formFields.platforms !== null) {
                options = [{value:-1,label:"Undefined"}, ...formFields.platforms.map(platform => ({ label: platform.platformName, value: platform.platformId }))];
            }
        break;

        case 'briefCreators':
            if(users){
                let allAssignes = [];
                if(users.all !== null) {
                    allAssignes = [...allAssignes, ...users.all];
                }
                options = allAssignes.filter(user=>user.statusName === "Actif").map(assignee=>({value: assignee.userId, label: assignee.userName || assignee.userEmail}));
            }

        break;
        
        case 'briefAssignees':
            if(users){
                let allAssignes = [];
                if(users.webdesigners !== null) {
                    allAssignes = [...allAssignes, ...users.webdesigners];
                }
                if(users.integrators !== null) {
                    allAssignes = [...allAssignes, ...users.integrators];
                }

                options = allAssignes.filter(user=>user.statusName === "Actif").map(assignee=>({value: assignee.userId, label: assignee.userName || assignee.userEmail}));
            }

        break;

        case 'briefGroups':
            if(allGroups){
                options = allGroups.map(group=>({value: group.groupId, label: group.groupName}));
            }
        break;

        default: 
            options = props.items.map(item => ({ label: item.label, value: item.value }));
    }

    if(error) {
        return <div>{error}</div>
    }

    if(fetching && options.length === 0) {
        return(
            <Loader />
        )
    }

    return(
        <ErrorBoundary>
            <div style={{display: 'inline-block'}} ref={wrapperRef} className={[styles.dropdown, props.className||''].join(' ')}>
                <ReactMultiSelectCheckboxes 
                    menuIsOpen={isOpen}
                    allowSelectAll={true}
                    className='brf-select-container'
                    classNamePrefix='brf-select'
                    id={props.id}
                    placeholderButtonLabel={props.placeholderButtonLabel} 
                    isMulti={props.isMulti}
                    options={[...options]} 
                    onChange={props.onChange}
                    rightAligned={props.rightAligned}
                    value={selectedItems||null}
                />
            </div>
        </ErrorBoundary>
    );
}

MultiSelectDropdown.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholderButtonLabel: PropTypes.string,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })), PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })
    ])
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    formFields: selectors.getFormFields(state),
    error: selectors.getError(state),
    filters: briefsSelectors.getFilters(state),
    allGroups: groupsSelectors.getGroups(state),
    users: usersSelectors.getUsers(state),
    assigneesList: briefsSelectors.getBriefAssigneesFilter(state),
    groupsList: briefsSelectors.getBriefGroupsFilter(state),
    creatorsList: briefsSelectors.getBriefCreatorsFilter(state)
});

export default connect(
    mapStateToProps,
    null
)(MultiSelectDropdown);