import React from 'react';
import PropTypes from 'prop-types';

function Error(props) {

    if(typeof(props.error) === 'undefined') {
        return null;
    }

    if(typeof(props.error.response) === 'undefined') {
        return null;
    }

    return(
        <div className="error">
            Error: {props.error.response.status}
            <p>{props.error.response.statusText}</p>
        </div>
    );
}

Error.propTypes = {
    error: PropTypes.object.isRequired
};

export default Error;