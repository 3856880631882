import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Button from '../Button';
import Loader from '../Loader';

function InlineEditor(props) {
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if(props.loading && props.error === null){
            setEdit(false);
        }
        // if (!props.loading && props.error === null) {
        //     setEdit(true)
        // }
    }, [props.loading, props.error]);

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return(
        <div className={styles.inlineEditorContainer}>
            <div className={styles.inlineEditorWrapper} onClick={()=>props.onClick ? props.onClick(props.value):()=>{}}>
                <input value={value} placeholder={props.placeholder} onChange={(e) => {
                    if(typeof props.onChange === 'function'){
                        props.onChange(e.target.value)
                    } else {
                        setValue(e.target.value);
                    }
                }}
                className={styles.inlineEditor}
                />
                {props.inlineEdit && 
                    <div className={styles.actionCtn} style={{visibility: value !== props.value ? 'visible' : 'hidden'}}>
                        <Button secondary={true} onClick={() => {
                            setEdit(false);
                            setValue(props.value);
                        }}>Cancel</Button>
                        {props.loading ? (
                            <Button type="submit">
                                <Loader type="button" />
                            </Button>
                        ) : (
                            <Button onClick={() => props.inlineEdit(value)}>Save</Button>
                        )}
                    </div>
                }
            </div>
            {props.error &&
                <div className={styles.inlineEditorError}>{props.error}</div>
            }
        </div>
    );
}

InlineEditor.protoTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object
}

export default InlineEditor;