export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_ALL_USERS_REQUEST = 'LOAD_ALL_USERS_REQUEST';
export const LOAD_ALL_USERS_SUCCESS = 'LOAD_ALL_USERS_SUCCESS';
export const LOAD_ALL_USERS_FAILURE = 'LOAD_ALL_USERS_FAILURE';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const CLEAR_INVITE_USER = 'CLEAR_INVITE_USER';

export const REMOVE_USER_REQUEST= 'REMOVE_USER_REQUEST'
export const REMOVE_USER_FAILURE= 'REMOVE_USER_FAILURE'
export const REMOVE_USER_SUCCESS= 'REMOVE_USER_SUCCESS'

export const UPDATE_USER_ME_REQUEST = 'UPDATE_USER_ME_REQUEST';
export const UPDATE_USER_ME_SUCCESS = 'UPDATE_USER_ME_SUCCESS';
export const UPDATE_USER_ME_FAILURE = 'UPDATE_USER_ME_FAILURE';

export const UPDATE_USER_ADMIN_REQUEST = 'UPDATE_USER_ADMIN_REQUEST';
export const UPDATE_USER_ADMIN_SUCCESS = 'UPDATE_USER_ADMIN_SUCCESS';
export const UPDATE_USER_ADMIN_FAILURE = 'UPDATE_USER_ADMIN_FAILURE';