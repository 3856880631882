import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../Helper/ErrorBoundary';
import { connect } from 'react-redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as types from '../../constants/actionTypes/users';
import * as selectors from '../../selectors/users';
import Loader from '../Loader';
import useClickOutside from './hooks';
import styles from './scss/select.module.scss';

function UsersDropdown({fetching, error, allUsers, webdesigners, integrators, loadAllUsers, ...props}) {

    const [options, setOptions] = useState([]);
    const [isOpen, setisOpen] = useState(false);

    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, (open)=>{
        setisOpen(open);
    });

    useEffect(() => {
        loadAllUsers();
    }, [loadAllUsers]);


    useEffect(() => {
        function initDropdown(users) {
            return users !== null ? users.map(user => ({ label: user.userName, value: user.userId })) : [];
        }

        switch(props.type) {
            case 'webdesigners':
                setOptions(initDropdown(webdesigners));
            break;
            
            case 'integrators':
                setOptions(initDropdown(integrators));
            break;

            default:
                setOptions(initDropdown(allUsers));
            break;
        }
    }, [allUsers, integrators, props.type, webdesigners]);

    

    if(error) {
        return <div>{error}</div>
    }

    if(fetching && options.length === 0) {
        return(
            <Loader />
        )
    }

    return(
        <ErrorBoundary>
            <div ref={wrapperRef} className={[styles.dropdown, props.className||''].join(' ')}>
                <ReactMultiSelectCheckboxes 
                    menuIsOpen={isOpen}
                    allowSelectAll={true}
                    className='brf-select-container'
                    classNamePrefix='brf-select'
                    id={props.id}
                    placeholderButtonLabel={props.placeholderButtonLabel} 
                    isMulti={props.isMulti}
                    options={[{label: props.noSelectionLabel || 'Unassigned', value:-1}, ...options]} 
                    onChange={props.onChange}
                    value={props.value}
                />
            </div>
        </ErrorBoundary>
    );
}

UsersDropdown.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholderButtonLabel: PropTypes.string,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    error: selectors.getError(state),
    allUsers: selectors.getAllUsers(state),
    webdesigners: selectors.getWebdesigners(state),
    integrators: selectors.getIntegrators(state)
});

const mapDispatchToProps = (dispatch) => ({
    loadAllUsers: () => dispatch({ type: types.LOAD_USERS_REQUEST })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersDropdown);