import React, { useState } from 'react';
import styles from './scss/button.module.scss';

function Button(props) {

    const [confirmation, setConfirmation] = useState(null)

    let className = [styles.baseBtn]
    if (props.secondary)
        className.push(styles.secondaryButton)
    if (props.tertiary) 
        className.push(styles.tertiaryButton)
    if (props.refresh) 
        className.push(styles.refresh)

    className.push(props.className)

    function clear() {
        clearTimeout(confirmation)
        setConfirmation(null)
    }

    function actionClick() {
        setConfirmation(setTimeout(clear, 3000))
    }   
    
    function confirmationClick() {
        clear()
        props.onClick()
    }

    return(
       <button title={props.title || ""} type={props.type || "button"} onClick={ props.clickToConfirm ? !confirmation ? actionClick : confirmationClick : props.onClick } disabled={ props.disabled } className={ className.join(' ') }>
            {!confirmation ? 
                props.children
            :
                'Click to confirm'
            }
       </button>
    );
}

export default Button;