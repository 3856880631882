import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './scss/chipsList.module.scss';
import Chip from './Chip';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import useClickOutside from '../MultiSelectDropdown/hooks';

function ChipsList(props) {

    const node = useRef();

    const [isOpen, setisOpen] = useState(false);
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, (open)=>{
        setisOpen(open);
    });

    function removeElement(item) {
        const itemsFull = props.collectionFull.filter(element => props.collection.filter(el => element.label === el).length > 0);
        // console.log(itemsFull.filter(element => element.label !== item));
        return itemsFull.filter(element => element.label !== item);
    }

    return(
        <>
        <ul ref={node} className={styles.chipsList + ' ' + props.style}>
            {props.collection.map((item, i) => {
                return(
                    <li key={i}>
                        <Chip value={item} editMode={props.editMode} onClick={(item) => props.onRemove(removeElement(item))} />
                    </li>
                )
            })}
        </ul>
        {props.editMode && 
            <div ref={wrapperRef} className={styles.addChipBtn}>
                <ReactMultiSelectCheckboxes 
                    menuIsOpen={isOpen}
                    allowSelectAll={true}
                    className='brf-select-container'
                    classNamePrefix='brf-select'
                    options={props.collectionFull} 
                    value={props.collectionFull.filter(item => props.collection.filter(selectItem => selectItem === item.label).length > 0)}
                    onChange={props.onChange}
                    getDropdownButtonLabel={()=>{return props.dropdownButtonLabel || 'Add';}}
                    placeholderButtonLabel='Add'
                />
            </div>
        }
        </>
    );
}

export default ChipsList;

ChipsList.protoTypes = {
    collection: PropTypes.array.isRequired,
    collectionFull: PropTypes.array.isRequired,
    editMode: PropTypes.bool.isRequired
}