import { createSelector } from 'reselect';

const selectGroups = (state) => state.groups;

export const getFetching = createSelector(
    [selectGroups],
    groups => groups.fetching
);

export const getError = createSelector(
    [selectGroups],
    groups => groups.error
);

export const getGroups = createSelector(
    [selectGroups],
    groups => groups.allGroups || []
);

export const getGroup = createSelector(
    [selectGroups],
    groups => groups.currentGroup
);

export const getRequests = createSelector(
    [selectGroups],
    groups => groups.requests
);