import { createSelector } from 'reselect';

export const getFormFields = (state) => state.formFields;

export const getFetching = createSelector(
    [getFormFields],
    formFields => formFields.fetching
);

export const getError = createSelector(
    [getFormFields],
    formFields => formFields.error
);

export const getStates = createSelector(
    [getFormFields],
    formFields => formFields.states || []
);

export const getSortedBriefsStates = createSelector(
    [getStates],
    states => states.sort((a, b) => a.stateCategoryId - b.stateCategoryId)
);

export const getCategories = createSelector(
    [getFormFields],
    formFields => formFields.stateCategories || []
);

export const getAllMarkets = createSelector(
    [getFormFields],
    formFields => formFields !== null ? formFields.markets : []
);