import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';

import styles from './scss/editor.module.scss';

function CustomEditor(props) {
  return(
    <Editor
      stripPastedStyles={true}
      wrapperClassName={styles.wrapper}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      editorState={props.editorState}
      placeholder={props.placeholder}
      onEditorStateChange={props.onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'link', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        }
      }}
      editorStyle={props.editorStyle}
    />
  );
}

CustomEditor.protoTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object
}
 
export default CustomEditor;