import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth || null;

export const getFetching = createSelector(
    [selectAuth],
    auth => auth !== null ? auth.fetching : null
);

export const getError = createSelector(
    [selectAuth],
    auth => auth !== null ? auth.error : null
);

export const getToken = createSelector(
    [selectAuth],
    auth => auth.token === null ? '' : auth.token.access_token
);

export const getAccount = createSelector(
    [selectAuth],
    auth => auth.account
);

export const isAdmin = createSelector(
    [selectAuth],
    auth => auth.account  === null ? false: auth.account.isAdmin
);

export const getPermissions = createSelector(
    [selectAuth],
    auth => auth.account === null ? null : auth.account.permissions
);

export const getRequests = createSelector(
    [selectAuth],
    auth => auth !== null ? auth.requests : null
);

export const isMarketUser = createSelector(
    [selectAuth],
    auth => auth.account ? auth.account.isMarketUser : null
);