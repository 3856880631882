import * as types from '../../constants/actionTypes/auth';

// TODO - move async requests to requests object
const initialState = {
    account: null,
    token: null,
    requests: {
        login: {
            fetching: false,
            error: null
        },
        confirmSignUp: {
            fetching: false,
            error: null
        },
        forgetPassword: {
            fetching: false,
            error: null
        },
        resetPassword: {
            fetching: false,
            error: null
        }
    },
    fetching: false,
    error: null
};

function auth(state: {} = initialState , action) {

    switch(action.type) {

        case types.LOGIN_REQUEST:
            return { ...state, fetching: true, error: null };
        
        case types.LOGIN_REQUEST_ACCESS_TOKEN:
            return { ...state, fetching: true, error: null };
        
        case types.LOGIN_SUCCESS:
            return { ...state, fetching: false, token: action.token, account: action.account };
        
        case types.LOGIN_FAILURE:
            return { ...state, fetching: false, token: null, account: null, error: action.error };

        case types.CONFIRM_SIGN_UP_REQUEST:
            return { ...state, requests: {
                ...state.requests,
                confirmSignUp: {
                    fetching: true, 
                    error: null
                }
            }
            };
        
        case types.CONFIRM_SIGN_UP_SUCCESS:
            return { ...state, requests: {
                ...state.requests,
                confirmSignUp: {
                    fetching: false
                }
            }
            };
        
        case types.CONFIRM_SIGN_UP_FAILURE:
            return { ...state, requests: {
                ...state.requests,
                confirmSignUp: {
                    fetching: false, 
                    error: action.error
                }
            }
            };

        case types.GET_ACCOUNT_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.GET_ACCOUNT_SUCCESS:
            return { ...state, fetching: false, account: action.account };

        case types.GET_ACCOUNT_FAILURE:
            return { ...state, fetching: false, account: null, error: action.error };

        case types.TOKEN_LOGIN:
            return { ...state, token: {
                access_token: action.token
            }};

        case types.LOGOUT_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOGOUT_SUCCESS:
            return initialState;

        case types.LOGOUT_FAILURE:
            return { ...state, fetching: false, error: action.error, account: null, token: null };

        case types.FORGET_PASSWORD_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.FORGET_PASSWORD_SUCCESS:
            return { ...state, fetching: false };

        case types.FORGET_PASSWORD_FAILURE:
            return { ...state, fetching: false, error: action.error };

        case types.RESET_PASSWORD_REQUEST:
            return { ...state, requests: {
                    ...state.requests,
                    resetPassword: {
                        fetching: true,
                        error: null
                    }
                }
            }

        case types.RESET_PASSWORD_SUCCESS:
            return { ...state, requests: {
                    ...state.requests,
                    resetPassword: {
                        fetching: false
                    }
                }
            }

        case types.RESET_PASSWORD_FAILURE:
            return { ...state, requests: {
                    ...state.requests,
                    resetPassword: {
                        fetching: false,
                        error: action.error
                    }
                }
            }

        default:
            return state;
    }
}

export default auth;