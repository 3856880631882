import {useEffect} from 'react';
/**
 * Hook that trigger clicks outside of the passed ref
 */
export default function useClickOutside(ref, callback) {
    /**
     * Handle clicked on outside of element
     */
    function handleClickOutside(event) {
        if((event.target.style.bottom === '0px')
        &&(event.target.style.left === '0px')
        &&(event.target.style.top === '0px')
        &&(event.target.style.right === '0px')
        &&(event.target.style.position === 'fixed')
        &&(event.target.style.zIndex === '1')){
            callback(false);
        }else{
            callback(ref.current && ref.current.contains(event.target));
        }
    }
  
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
}