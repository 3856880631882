import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes/stats';
import axios from 'axios';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function getStats(fromDate, toDate, token) {
    const params = {
        from: fromDate,
        to: toDate
    };
    return axios({
        method: 'get',
        url: `${API_URI}/getStatistics`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: params
    });
}

export function* workerLoadStats(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(getStats, payload.fromDate, payload.toDate, token);
        const stats = response.data;

        yield put({ type: types.LOAD_STATS_SUCCESS, stats });
    } catch (error) {
        yield put({ type: types.LOAD_STATS_FAILURE, error });
    }
}

export function* watcherStats() {
    yield takeLatest(types.LOAD_STATS_REQUEST, workerLoadStats);
}