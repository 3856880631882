import { createSelector } from 'reselect';

const selectSearch = (state) => state.search || null;

export const getFetching = createSelector(
    [selectSearch],
    search => search !== null ? search.fetching : null
);

export const getResults = createSelector(
    [selectSearch],
    search => search !== null ? search.results.items : null
);

export const getTotal = createSelector(
    [selectSearch],
    search => search !== null ? search.results.total : null
);

export const getError = createSelector(
    [selectSearch],
    search => search !== null ? search.error : null
);

export const getRequests = createSelector(
    [selectSearch],
    search => search !== null ? search.requests : null
);