import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as types from '../constants/actionTypes/auth';
import * as formFieldsTypes from '../constants/actionTypes/formFields';
import axios from 'axios';
import authService from '../services/authService';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';
import qs from 'qs';

function login(email = null, password = null, remember = 0) {
    return axios({
        method: 'post',
        url: `${API_URI}/login`,
        data: {
            email: email,
            password: password,
            remember_me: remember
        }
    });
}

function loginWithAccessToken(code = null) {
    let data = qs.stringify({
        'code': `${code}`
    });
    return axios({
        method: 'post',
        url: `${API_URI}/myid`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    });
}

function confirmSignUp(name, password, confirmPassword, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/confirmSignup`,
        data: {
            name: name,
            password: password,
            password_confirmation: confirmPassword,
            token: token
        }
    });
}

function getAccount(token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getCurrentUser`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
}

function forgetPassword(email) {
    return axios({
        method: 'post',
        url: `${API_URI}/../password/create`,
        data: {
            email: email
        }
    });
}

function resetPassword(password, confirmPassword, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/../password/reset`,
        data: {
            password: password,
            password_confirmation: confirmPassword,
            token: token
        }
    });
}

export function* workerLogin(payload) {
    try{
        const response = yield call(login, payload.email, payload.password, payload.remember);
        const token = response.data.token;
        const account = response.data.account;

        yield call(authService.addToken, token.access_token);
        yield put({ type: types.LOGIN_SUCCESS, token, account });

        yield put({ type: formFieldsTypes.LOAD_FORM_FIELDS_REQUEST });

        yield put(push('/'));

    } catch (error) {
        yield put({ type: types.LOGIN_FAILURE, error });
    }
}

export function* workerLoginWithAcessToken(payload) {
    try{
        
        const response = yield call(loginWithAccessToken, payload.code);
        const token = response.data.token;
        const account = response.data.account;

        yield call(authService.addToken, token.access_token);
        yield put({ type: types.LOGIN_SUCCESS, token, account });

        yield put({ type: formFieldsTypes.LOAD_FORM_FIELDS_REQUEST });

        yield put(push('/'));

    } catch (error) {
        yield put({ type: types.LOGIN_FAILURE, error });
    }
}

export function* workerConfirmSignUp(payload) {
    try{
        yield call(confirmSignUp, payload.name, payload.password, payload.confirmPassword, payload.token);

        yield put({ type: types.CONFIRM_SIGN_UP_SUCCESS });

        yield put(push('/login'));

    } catch (error) {
        yield put({ type: types.CONFIRM_SIGN_UP_FAILURE, error });
    }
}

function* workerGetAccount() {
    try{
        const token = yield select(getToken);

        const response = yield call(getAccount, token);
        const account = response.data;

        yield put({ type: types.GET_ACCOUNT_SUCCESS, account });

        yield put({ type: formFieldsTypes.LOAD_FORM_FIELDS_REQUEST });

    } catch (error) {
        // yield put({ type: types.GET_ACCOUNT_FAILURE, error });
        yield put({ type: types.LOGOUT_REQUEST, error });
    }
}

export function* workerCheckAccess(payload) {
    if(payload.token === null || payload.token.length === 0) {
        yield put(push('/login'));
    }
}

export function* workerLogout() {
    try{
        yield call(authService.removeToken);
        yield put({ type: types.LOGOUT_SUCCESS });

        yield put(push('/login'));

    } catch (error) {
        yield put({ type: types.LOGOUT_FAILURE, error });
    }
}

export function* workerForgetPassword(payload) {
    try{
        yield call(forgetPassword, payload.email);

        yield put({ type: types.FORGET_PASSWORD_SUCCESS });

        yield put(push('/forget-password-confirmation'));

    } catch (error) {
        yield put({ type: types.FORGET_PASSWORD_FAILURE, error });
    }
}

export function* workerResetPassword(payload) {
    try{
        yield call(resetPassword, payload.password, payload.confirmPassword, payload.token);

        yield put({ type: types.RESET_PASSWORD_SUCCESS });

        yield put(push('/reset-password-confirmation'));

    } catch (error) {
        yield put({ type: types.RESET_PASSWORD_FAILURE, error });
    }
}

export function* watcherAuthentication() {
    yield takeLatest(types.LOGIN_REQUEST, workerLogin);
    yield takeLatest(types.LOGIN_REQUEST_ACCESS_TOKEN, workerLoginWithAcessToken);
    yield takeLatest(types.CONFIRM_SIGN_UP_REQUEST, workerConfirmSignUp);
    yield takeLatest(types.CHECK_ACCESS, workerCheckAccess);
    yield takeLatest(types.GET_ACCOUNT_REQUEST, workerGetAccount);
    yield takeLatest(types.LOGOUT_REQUEST, workerLogout);
    yield takeLatest(types.FORGET_PASSWORD_REQUEST, workerForgetPassword);
    yield takeLatest(types.RESET_PASSWORD_REQUEST, workerResetPassword);
}