import React from 'react';
import PropTypes from 'prop-types';

import styles from './scss/state.module.scss';

function State({ actions, ...props }) {  
  const statusStyle = styles[`status_${props.status}`]
  const iconStyle = styles[`icon_${props.icon}`]

  return(
    <div className={styles.subStep + ' ' + statusStyle + ' ' + iconStyle}
      onMouseEnter={() => props.onmouseenter()}
      onMouseLeave={() => props.onmouseleave()}
    >
      {/* {props.status === 'process' &&
        <span className={styles.stepname}>{props.name}</span>
      } */}

      {props.selected && 
        <span className={styles.stepname}>{props.name}</span>
      }

    </div>
  );
}
 
export default State

State.protoTypes = {
  icon: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}