import React, { useState, useRef } from 'react';
import { useFormState } from 'react-use-form-state';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';
import * as selectors from '../../selectors/briefs';
import ErrorBoundary from '../../components/Helper/ErrorBoundary';
import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Editor from '../../components/Editor';
import DatePicker from 'react-datepicker';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { FilePond, registerPlugin } from 'react-filepond';
import 'react-datepicker/dist/react-datepicker.css';
import 'filepond/dist/filepond.min.css';
import { Link } from 'react-router-dom';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import styles from './index.module.scss';
import Button from '../../components/Button';
import ChipsList from '../../components/Chips/ChipsList';
import * as formFieldSelectors from '../../selectors/formFields';

import moment from 'moment';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function BriefCreation({ fetching, error, createBrief, formFields, ...props }) {

    const [formState, { text }] = useFormState();
    const [liveDate, setLiveDate] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [weight, setWeight] = useState([]);
    const [formValid, setFormValid] = useState(true);
    const [missingFields, setMissingFields] = useState([]);
    const [open, onInputClick] = useState(false);
    
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [files, setFiles] = useState([]);
    const pond = useRef(null);

    const offerBrief = props.match.path === '/offer-brief-creation' ? true : false;
    const headerTitle = offerBrief ? `Create new Offer Brief` : `Create new Brief`;

    let allMarkets = [];

    if(formFields.myMarkets){
        allMarkets = formFields.myMarkets.map(market=>({...market, label:market.marketSlug, value:market.marketId}));
    }

    function offDays(date) {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    }

    function handleSubmit(e) {

        e.preventDefault();
        
        // Rules 
        let missingFds = [];
        if(formState.values.title.length<1){
            missingFds.push('Brief Title');
        }

        let selectedMarkets = markets.map(market => market.value);
        if(markets.length<1){
            //
            missingFds.push('Markets');
        }

        if(!liveDate){
            //
            missingFds.push('Live Date');
        }

        let desc = draftToHtml(convertToRaw(description.getCurrentContent()));
        let cleanDescription = desc.replace(/<\/?[^>]+(>|$)/g, "");
        if(cleanDescription.length<2){
            //
            missingFds.push('Description');
        }

        if(missingFds.length>0){
            setFormValid(false);
            setMissingFields(missingFds);
            return;
        }
        
        const form = {
            title: formState.values.title,
            description: desc,
            type: offerBrief ? 2 : 1,
            weight: weight.value,
            liveDate: liveDate,
            markets: selectedMarkets
        }

        createBrief(form, files);
    }

    return(
        <ErrorBoundary>
            <PageHeader title={headerTitle}></PageHeader>

            <form className={styles.formContainer} onSubmit={(e) => handleSubmit(e)}>

                <div className={styles.line}>
                    <div className={styles.inputContainer + ' ' + styles.titleInput}>
                        <label htmlFor="title">Brief Title</label>
                        <input placeholder="Type a title" autoComplete="off" id="title" {...text('title')} minLength="2" maxLength="255"/>
                    </div>

                    <div className={styles.inputContainer}>
                        <label htmlFor="liveDate">Live Date</label>
                        <DatePicker
                            id="liveDate"
                            onClickOutside={()=>onInputClick(false)}  
                            open={open}
                            placeholderText="ddd, yyyy/mm/dd"
                            readOnly={'readOnly'}
                            disabledKeyboardNavigation={true}
                            selected={liveDate}
                            onChange={day => {
                                day.setHours( day.getHours() + 10 );
                                setLiveDate(day)
                            }}
                            monthsShown={2}
                            minDate={new Date()}
                            filterDate={offDays}
                            autoComplete="off"
                            minTime={setHours(setMinutes(new Date(), 30), 9)}
                            maxTime={setHours(setMinutes(new Date(), 30), 18)}
                            dateFormat="eee, yyyy/MM/dd"
                            className={styles.liveDateInput}
                            onInputClick={()=>{
                                onInputClick(!open);
                            }}
                            onSelect= {(value)=>{
                                onInputClick(!open);
                                if(value){
                                    const today = new Date();
                                    const diffTime = Math.abs(value.getTime() - (today).getTime());
                                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                    //We need to add 7 workings days + 2 days of week-end (10 days total since we must not include today)
                                    //If we're a Wednesday or a Tuesday, add one or two more days to not result in a week-end day
                                    const minDate = addDays(today, (today.getDay() === 4 ||today.getDay() === 5) ? 11 : 9)
                                    if (diffDays <3) {
                                        alert('ERROR')
                                    } else if(diffDays<10){
                                        alert(`WARNING : Minimum live date is ${moment(minDate).format('ddd, YYYY/MM/DD')} (=Today + 7 working days)`);
                                    }
                                    if (value.getDay() === 5) {
                                        alert("Friday");
                                    }
                                }
                            }}
                            popperModifiers={{
                                offset: {
                                    enabled: true,
                                    offset: '5px, 10px'
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={styles.inputContainer}>
                    <label htmlFor="markets">Markets</label>
                    <ChipsList 
                        dropdownButtonLabel={'Add markets'}
                        id="markets"
                        collection={markets.map(market=>market.label)} 
                        collectionFull={allMarkets} 
                        editMode={true}
                        onChange={(newMarkets) => {setMarkets(newMarkets)}}
                        onRemove={(newMarkets) => {setMarkets(newMarkets)}}
                    />
                </div>

                {/* <div className={styles.inputContainer}>
                    <label htmlFor="weight">Weight</label>
                    <MultiSelectDropdown 
                        id="weight"
                        type="weights"
                        placeholderButtonLabel="Weight" 
                        isMulti={false}
                        onChange={(selectedOption) => setWeight(selectedOption)}
                    />
                </div> */}

                <div className={styles.inputContainer}>
                    <label htmlFor="description">Description</label>
                    <Editor
                        placeholder="Type a description"
                        id="description"
                        editorState={description}
                        onEditorStateChange={(txt) => setDescription(txt)}
                        editorStyle={{minHeight: '122px'}}
                    />
                </div>
                
                <div className={styles.uploader}>
                <FilePond
                    ref={pond}
                    files={files}
                    allowMultiple={true}
                    onupdatefiles={setFiles}
                />
                </div>

                <div className={styles.actionBtnContainer}>
                    {!formValid&&
                        <div style={{ color: "red", alignSelf: 'center', marginRight:'20px' }}>You need to fill missing inputs : {missingFields.join(', ')}</div>
                    }
                    {fetching ? (
                        <Button type="submit">
                            <Loader type="button" />
                        </Button>
                    ) : (
                        <div>
                            <Button type="submit" value="Create" primary>Create</Button>

                            <Link to={'/'}>
                                <Button secondary={true} type="submit" value="Cancel">Cancel</Button>
                            </Link>
                        </div>
                    )} 
   
                </div>

                {error && 
                    <div style={{ color: "red"}}>{error.response ? error.response.statusText:"error occured"}</div>
                }
                
            </form>

        </ErrorBoundary>
    );
}

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    error: selectors.getError(state),
    formFields: formFieldSelectors.getFormFields(state),
});

const mapDispatchToProps = (dispatch) => ({
    createBrief: (form, files) => dispatch({ type: types.CREATE_BRIEF_REQUEST, form: form, files: files })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BriefCreation);