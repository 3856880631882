export const CREATE_BRIEF_REQUEST = 'CREATE_BRIEF_REQUEST';
export const CREATE_BRIEF_SUCCESS = 'CREATE_BRIEF_SUCCESS';
export const CREATE_BRIEF_FAILURE = 'CREATE_BRIEF_FAILURE';
export const LOAD_ALL_BRIEFS_REQUEST = 'LOAD_ALL_BRIEFS_REQUEST';
export const LOAD_ALL_BRIEFS_SUCCESS = 'LOAD_ALL_BRIEFS_SUCCESS';
export const LOAD_ALL_BRIEFS_FAILURE = 'LOAD_ALL_BRIEFS_FAILURE';
export const LOAD_NEXT_BRIEFS_REQUEST = 'LOAD_NEXT_BRIEFS_REQUEST';
export const LOAD_NEXT_BRIEFS_SUCCESS = 'LOAD_NEXT_BRIEFS_SUCCESS';
export const LOAD_NEXT_BRIEFS_FAILURE = 'LOAD_NEXT_BRIEFS_FAILURE';
export const LOAD_BRIEF_REQUEST = 'LOAD_BRIEF_REQUEST';
export const LOAD_BRIEF_SUCCESS = 'LOAD_BRIEF_SUCCESS';
export const LOAD_BRIEF_FAILURE = 'LOAD_BRIEF_FAILURE';
export const UPDATE_STATE_REQUEST = 'UPDATE_STATE_REQUEST';
export const UPDATE_STATE_SUCCESS = 'UPDATE_STATE_SUCCESS';
export const UPDATE_STATE_FAILURE = 'UPDATE_STATE_FAILURE';
export const PARALLELIZE_STATE_REQUEST = 'PARALLELIZE_STATE_REQUEST';
export const PARALLELIZE_STATE_SUCCESS = 'PARALLELIZE_STATE_SUCCESS';
export const PARALLELIZE_STATE_FAILURE = 'PARALLELIZE_STATE_FAILURE';
export const UNPARALLELIZE_STATE_REQUEST = 'UNPARALLELIZE_STATE_REQUEST';
export const UNPARALLELIZE_STATE_SUCCESS = 'UNPARALLELIZE_STATE_SUCCESS';
export const UNPARALLELIZE_STATE_FAILURE = 'UNPARALLELIZE_STATE_FAILURE';
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const VALIDATE_BRIEF_REQUEST = 'VALIDATE_BRIEF_REQUEST';
export const VALIDATE_BRIEF_SUCCESS = 'VALIDATE_BRIEF_SUCCESS';
export const VALIDATE_BRIEF_FAILURE = 'VALIDATE_BRIEF_FAILURE';
export const DON_T_VALIDATE_BRIEF_REQUEST = 'DON_T_VALIDATE_BRIEF_REQUEST';
export const DON_T_VALIDATE_BRIEF_SUCCESS = 'DON_T_VALIDATE_BRIEF_SUCCESS';
export const DON_T_VALIDATE_BRIEF_FAILURE = 'DON_T_VALIDATE_BRIEF_FAILURE';
export const EDIT_BRIEF_TITLE_REQUEST = 'EDIT_BRIEF_TITLE_REQUEST';
export const EDIT_BRIEF_TITLE_SUCCESS = 'EDIT_BRIEF_TITLE_SUCCESS';
export const EDIT_BRIEF_TITLE_FAILURE = 'EDIT_BRIEF_TITLE_FAILURE';
export const EDIT_BRIEF_DESCRIPTION_REQUEST = 'EDIT_BRIEF_DESCRIPTION_REQUEST';
export const EDIT_BRIEF_DESCRIPTION_SUCCESS = 'EDIT_BRIEF_DESCRIPTION_SUCCESS';
export const EDIT_BRIEF_DESCRIPTION_FAILURE = 'EDIT_BRIEF_DESCRIPTION_FAILURE';
export const ADD_BRIEF_FILE_REQUEST = 'ADD_BRIEF_FILE_REQUEST';
export const ADD_BRIEF_FILE_SUCCESS = 'ADD_BRIEF_FILE_SUCCESS';
export const ADD_BRIEF_FILE_FAILURE = 'ADD_BRIEF_FILE_FAILURE';
export const CHANGE_LIVE_DATE_REQUEST = 'CHANGE_LIVE_DATE_REQUEST';
export const CHANGE_LIVE_DATE_SUCCESS = 'CHANGE_LIVE_DATE_SUCCESS';
export const CHANGE_LIVE_DATE_FAILURE = 'CHANGE_LIVE_DATE_FAILURE';
export const CHANGE_PLATFORMS_REQUEST = 'CHANGE_PLATFORMS_REQUEST';
export const CHANGE_PLATFORMS_SUCCESS = 'CHANGE_PLATFORMS_SUCCESS';
export const CHANGE_PLATFORMS_FAILURE = 'CHANGE_PLATFORMS_FAILURE';
export const CHANGE_MARKETS_REQUEST = 'CHANGE_MARKETS_REQUEST';
export const CHANGE_MARKETS_SUCCESS = 'CHANGE_MARKETS_SUCCESS';
export const CHANGE_MARKETS_FAILURE = 'CHANGE_MARKETS_FAILURE';
export const CHANGE_WEBDESIGNER_ASSIGNMENT_REQUEST = 'CHANGE_WEBDESIGNER_ASSIGNMENT_REQUEST';
export const CHANGE_WEBDESIGNER_ASSIGNMENT_SUCCESS = 'CHANGE_WEBDESIGNER_ASSIGNMENT_SUCCESS';
export const CHANGE_WEBDESIGNER_ASSIGNMENT_FAILURE = 'CHANGE_WEBDESIGNER_ASSIGNMENT_FAILURE';
export const CHANGE_INTEGRATOR_ASSIGNMENT_REQUEST = 'CHANGE_INTEGRATOR_ASSIGNMENT_REQUEST';
export const CHANGE_INTEGRATOR_ASSIGNMENT_SUCCESS = 'CHANGE_INTEGRATOR_ASSIGNMENT_SUCCESS';
export const CHANGE_INTEGRATOR_ASSIGNMENT_FAILURE = 'CHANGE_INTEGRATOR_ASSIGNMENT_FAILURE';
export const CHANGE_WEIGHT_REQUEST = 'CHANGE_WEIGHT_REQUEST';
export const CHANGE_WEIGHT_SUCCESS = 'CHANGE_WEIGHT_SUCCESS';
export const CHANGE_WEIGHT_FAILURE = 'CHANGE_WEIGHT_FAILURE';
export const TOGGLE_CLOSE_BRIEF_REQUEST = 'TOGGLE_CLOSE_BRIEF_REQUEST';
export const TOGGLE_CLOSE_BRIEF_SUCCESS = 'TOGGLE_CLOSE_BRIEF_SUCCESS';
export const TOGGLE_CLOSE_BRIEF_FAILURE = 'TOGGLE_CLOSE_BRIEF_FAILURE';
export const LOAD_BRIEFS_TYPES_REQUEST = 'LOAD_BRIEFS_TYPES_REQUEST';
export const LOAD_BRIEFS_TYPES_SUCCESS = 'LOAD_BRIEFS_TYPES_SUCCESS';
export const LOAD_BRIEFS_TYPES_FAILURE = 'LOAD_BRIEFS_TYPES_FAILURE';
export const LOAD_BRIEFS_WEIGHTS_REQUEST = 'LOAD_BRIEFS_WEIGHTS_REQUEST';
export const LOAD_BRIEFS_WEIGHTS_SUCCESS = 'LOAD_BRIEFS_WEIGHTS_SUCCESS';
export const LOAD_BRIEFS_WEIGHTS_FAILURE = 'LOAD_BRIEFS_WEIGHTS_FAILURE';
export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const ENABLE_FILE_REQUEST = 'ENABLE_FILE_REQUEST';
export const ENABLE_FILE_SUCCESS = 'ENABLE_FILE_SUCCESS';
export const ENABLE_FILE_FAILURE = 'ENABLE_FILE_FAILURE';


export const MODIFY_FILES_REQUEST = 'MODIFY_FILES_REQUEST';
export const MODIFY_FILES_SUCCESS = 'MODIFY_FILES_SUCCESS';
export const MODIFY_FILES_FAILURE = 'MODIFY_FILES_FAILURE';

export const CHANGE_BRIEFS_VISIBILITY_FILTER = 'CHANGE_BRIEFS_VISIBILITY_FILTER';
export const CHANGE_BRIEF_TYPES_FILTER = 'CHANGE_BRIEF_TYPES_FILTER';
export const CHANGE_BRIEF_MARKETS_FILTER = 'CHANGE_BRIEF_MARKETS_FILTER';
export const CHANGE_BRIEF_STATES_FILTER = 'CHANGE_BRIEF_STATES_FILTER';
export const CHANGE_BRIEF_LIVE_DATE_FILTER = 'CHANGE_BRIEF_LIVE_DATE_FILTER';
export const CHANGE_BRIEF_SEARCH_FILTER = 'CHANGE_BRIEF_SEARCH_FILTER';
export const CHANGE_BRIEF_SORT = 'CHANGE_BRIEF_SORT';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ASSIGN_TO_ME_BRIEF_REQUEST = 'ASSIGN_TO_ME_BRIEF_REQUEST';
export const ASSIGN_TO_ME_BRIEF_SUCCESS = 'ASSIGN_TO_ME_BRIEF_SUCCESS';
export const ASSIGN_TO_ME_BRIEF_ERROR = 'ASSIGN_TO_ME_BRIEF_ERROR';
export const UPDATE_BRIEF_IN_LIST = 'UPDATE_BRIEF_IN_LIST';

export const CHANGE_BRIEF_CREATORS_FILTER = 'CHANGE_BRIEF_CREATORS_FILTER';
export const CHANGE_BRIEF_ASSIGNEES_FILTER = 'CHANGE_BRIEF_ASSIGNEES_FILTER';
export const CHANGE_BRIEF_GROUPS_FILTER = 'CHANGE_BRIEF_GROUPS_FILTER';
