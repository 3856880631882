import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../Helper/ErrorBoundary';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';
import * as formFieldsSelectors from '../../selectors/formFields';
import * as briefSelectors from '../../selectors/briefs';
import * as usersSelectors from '../../selectors/users';
import ReactHtmlParser from 'react-html-parser';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import styles from './scss/briefInfo.module.scss';
import MultiSelectDropdown from '../MultiSelectDropdown';
import UsersDropdown from '../MultiSelectDropdown/UsersDropdown';
import ChipsList from '../Chips/ChipsList';
import Editor from '../Editor';
import Button from '../Button';
import Loader from '../Loader';
import * as authSelectors from '../../selectors/auth';
import moment from 'moment';
import urlify from '../../utils/urlify';
import Linkify from '../../components/Linkify';

// TODO - Display previous data when the user cancel the edit mode.

function BriefInfo({ allMarkets, assignees, assigneeWebdesigner, assigneeIntegrator, webdesignersList, integratorsList, requests, editDescription, descriptionFetch, changeAssigneeWebdesigner, changeAssigneeIntegrator, changeWeight, changePlatforms, changeMarkets, permissions, ...props }) {

  const [webdesigner, setWebdesigner] = useState(null);
  const [webdesignerChange, setWebdesignerChange] = useState(false);
  const [integrator, setIntegrator] = useState(null);
  const [integratorChange, setIntegratorChange] = useState(false);
  const [weight, setWeight] = useState(null);
  const [weightChange, setWeightChange] = useState(false);
  const [platforms, setPlatforms] = useState(null);
  const [platformsChange, setPlatformsChange] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [marketsChange, setMarketsChange] = useState(false);
  const [description, setDescription] = useState(null);
  const [descriptionChange, setDescriptionChange] = useState(false);

  useEffect(() => {
    setWebdesigner(assigneeWebdesigner !== null ? ({ label: assigneeWebdesigner.userName, value: assigneeWebdesigner.userId }) : null);
    setWebdesignerChange(false);
  }, [props.editMode, assigneeWebdesigner]);
  useEffect(() => {
    setIntegrator(assigneeIntegrator !== null ? ({ label: assigneeIntegrator.userName, value: assigneeIntegrator.userId }) : null);
    setIntegratorChange(false);
  }, [props.editMode, assigneeIntegrator]);
  useEffect(() => {
    setWeight(props.data.weightId !== null ? { label: props.data.weightName, value: props.data.weightId } : null);
    setWeightChange(false);
  }, [props.editMode, props.data.weightId, props.data.weightName]);
  useEffect(() => {
    setPlatforms(props.data.platforms.length > 0 ? props.data.platforms.map(platform => ({ label: platform.platformName, value: platform.platformId })) : null);
    setPlatformsChange(false);
  }, [props.editMode, props.data.platforms]);
  useEffect(() => {
    setMarkets(props.data.markets !== null ? props.data.markets.map(market => market.marketSlug) : null);
    setMarketsChange(false);
  }, [props.editMode, props.data.markets]);
  useEffect(() => {
    setDescription(EditorState.createWithContent(stateFromHTML(props.data.briefDesc)))
    setDescriptionChange(false)
  }, [props.editMode, props.data.briefDesc]);


  function saveDescription() {
    editDescription(draftToHtml(convertToRaw(description.getCurrentContent())));
  }


  if(allMarkets === null) {
    return <Loader />
  }

  let creation_date = moment(props.data.created_at);
  creation_date = creation_date.add(creation_date.utcOffset(), 'm');

  return (
    <ErrorBoundary>
      <div className={styles.infosContainer}>

        <div className={styles.infosTop}>
          <div className={styles.infoLine}>
            <div>
              <label>Created by</label>
              <p>{props.data.owner.userName}</p>
            </div>
            <div>
              <label>Created on</label>
              <p>{creation_date.format('ddd, YYYY/MM/DD kk:mm')}</p>
            </div>
          </div>

          <div className={styles.infoLine}>
            <div>
              <label htmlFor="webdesigner">Webdesigner</label>
              {props.editMode && permissions.brief_canViewDSPFields ? (
                <div className={styles.inputContainer}>
                  <UsersDropdown 
                      type="webdesigners"
                      placeholderButtonLabel="Webdesigner" 
                      isMulti={false}
                      onChange={selectedOption => {
                        setWebdesigner(selectedOption);
                        setWebdesignerChange(true);
                      }}
                      value={webdesigner}
                  />

                  {webdesignerChange &&
                    <div className={styles.buttonSave}>
                      <Button secondary onClick={() => {
                        if(!requests.webdesignerAssignment.fetching) {
                          setWebdesigner(assigneeWebdesigner !== null ? ({ label: assigneeWebdesigner.userName, value: assigneeWebdesigner.userId }) : null);
                          setWebdesignerChange(false);
                        }
                      }}>Delete</Button>
                      {requests.webdesignerAssignment.fetching ? (
                        <Button>
                          <Loader type="button" />
                        </Button>
                      ) : (
                        <Button 
                          onClick={() => {
                            changeAssigneeWebdesigner([webdesigner.value]);
                          }}
                        >Save</Button>
                      )}
                    </div>
                  }

                  {requests.webdesignerAssignment.error &&
                    <div style={{color:'red'}}>{requests.webdesignerAssignment.error.response.statusText}</div>
                  }

                </div>
              ) : (
                <p>{assigneeWebdesigner !== null ? assigneeWebdesigner.userName : 'Unassigned'}</p>
              )
              }
            </div>
            <div>
              <label htmlFor="integrator">Integrator</label>
              {props.editMode && permissions.brief_canViewDSPFields ? (
                <div className={styles.inputContainer}>
                  <UsersDropdown 
                      type="integrators"
                      placeholderButtonLabel="Integrator" 
                      isMulti={false}
                      onChange={selectedOption => {
                        setIntegrator(selectedOption);
                        setIntegratorChange(true);
                      }}
                      value={integrator}
                  />

                  {integratorChange &&
                    <div className={styles.buttonSave}>
                      <Button secondary onClick={() => {
                        if(!requests.integratorAssignment.fetching) {
                          setIntegrator(assigneeIntegrator !== null ? ({ label: assigneeIntegrator.userName, value: assigneeIntegrator.userId }) : null);
                          setIntegratorChange(false);
                        }
                      }}>Cancel</Button>
                      {requests.integratorAssignment.fetching ? (
                        <Button>
                          <Loader type="button" />
                        </Button>
                      ) : (
                        <Button 
                          onClick={() => {
                            changeAssigneeIntegrator([integrator.value]);
                          }}
                        >Save</Button>
                      )}
                    </div>
                  }

                  {requests.integratorAssignment.error &&
                    <div style={{color:'red'}}>{requests.integratorAssignment.error.response.statusText}</div>
                  }

                </div>
              ) : (
                <p>{assigneeIntegrator !== null ? assigneeIntegrator.userName : 'Unassigned'}</p>
              )
              }
            </div>
          </div>
        </div>

        <div className={styles.infosBottomBar}>
          <div className={styles.infoLine}>
          
            <div>
              {props.editMode && permissions.brief_canViewDSPFields ? (
                <>
                  <label htmlFor="weight">Weight</label>
                  <div className={styles.inputContainer}>
                    <MultiSelectDropdown 
                        type="weights"
                        placeholderButtonLabel="Weight" 
                        isMulti={false}
                        onChange={selectedOption => {
                          setWeight(selectedOption);
                          setWeightChange(true);
                        }}
                        value={weight}
                    />
                  </div>

                  {weightChange &&
                    <div className={styles.buttonSave}>
                      <Button secondary onClick={() => {
                        if(!requests.weight.fetching) {
                          setWeight(props.data.weightId !== null ? { label: props.data.weightName, value: props.data.weightId } : null);
                          setWeightChange(false);
                        }
                      }}>Cancel</Button>
                      {requests.weight.fetching ? (
                        <Button>
                          <Loader type="button" />
                        </Button>
                      ) : (
                        <Button 
                          onClick={() => {
                            changeWeight(weight);
                          }}
                        >Save</Button>
                      )}
                    </div>
                  }

                  {requests.weight.error &&
                    <div style={{color:'red'}}>Server Error</div>
                  }
                </>
              ) : (
                <>
                  <label>Weight : </label>
                  <span>{props.data.weightName || 'Undefined'}</span>
                </>
              )
              }
            </div>

            {/* Platforms */}
            <div>
              {props.editMode && permissions.brief_canViewDSPFields ? (
                  <>
                      <label htmlFor="platforms">Platforms</label>
                      <div className={styles.inputContainer}>
                        <MultiSelectDropdown 
                            type="platforms"
                            placeholderButtonLabel="Platforms" 
                            isMulti={true}
                            onChange={selectedOption => {
                              setPlatforms(selectedOption); 
                              setPlatformsChange(true);
                            }}
                            value={platforms}
                        />

                        {platformsChange &&
                          <div className={styles.buttonSave}>
                            <Button secondary onClick={() => {
                              if(!requests.platforms.fetching){
                                setPlatforms(props.data.platforms.length > 0 ? props.data.platforms.map(platform => ({ label: platform.platformName, value: platform.platformId })) : null);
                                setPlatformsChange(false);
                              } 
                            }}>Cancel</Button>
                            {requests.platforms.fetching ? (
                              <Button>
                                <Loader type="button" />
                              </Button>
                            ) : (
                              <Button 
                                onClick={() => {
                                  changePlatforms(platforms);
                                }}
                              >Save</Button>
                            )}
                          </div>
                        }

                        {requests.platforms.error &&
                          <div style={{color:'red'}}>{requests.platforms.error.response.statusText}</div>
                        }
                    
                      </div>
                  </>
                ) : (
                <>
                  <label>Platforms : </label>
                  <span>
                    {props.data.platforms.map((platform, i) => {

                      const comma = i < props.data.platforms.length - 1 ? ', ' : '';

                      return (
                        <span key={`platform-${i}`}>
                          {platform.platformName}{comma}
                        </span>
                      );
                    })}
                    <>
                    {props.data.platforms.length === 0 && ('Undefined')}
                    </>
                  </span>
                </>
              )}
            </div>

          </div>
          {/*<label htmlFor="markets">Markets</label>*/}
          <div className={styles.marketsContainer}>
            
            {markets !== null &&
              <ChipsList 
                style={styles.markets} 
                collection={markets.map(market => market.label || market)} 
                collectionFull={allMarkets.map(market => ({ label: market.marketSlug, value: market.marketId }))} 
                editMode={props.editMode}
                dropdownButtonLabel={'Add markets'}
                onChange={(newMarkets) => {
                  setMarkets(newMarkets);
                  setMarketsChange(true);
                }}
                onRemove={(newMarkets) => {
                  setMarkets(newMarkets);
                  setMarketsChange(true);
                }}
              />
            }

            {props.editMode && marketsChange &&
              <div className={styles.buttonSave}>
                <Button secondary onClick={() => {
                  if(!requests.markets.fetching) {
                    setMarkets(props.data.markets !== null ? props.data.markets.map(market => market.marketSlug) : null);
                    setMarketsChange(false);
                  }
                }}>Cancel</Button>
                {requests.markets.fetching ? (
                  <Button>
                    <Loader type="button" />
                  </Button>
                ) : (
                  <Button 
                    onClick={() => {
                      changeMarkets(markets);
                    }}
                  >Save</Button>
                )}
              </div>
            }


            {requests.markets.error &&
              <div>{requests.markets.error.response.statusText}</div>
            }
          </div>
        </div>
      </div>

      <h4 className={styles.descriptionTitle}>Description</h4>
     
      <div className={styles.description}>

        {props.editMode ? (
          <>
            <Editor
              editorState={description}
              onEditorStateChange={txt => {
                setDescription(txt);
                setDescriptionChange(true);
              }}
            />

            {descriptionChange &&
              <div className={styles.buttonSave}>
                <Button secondary onClick={() => {
                  if(!descriptionFetch.fetching) {
                    setDescription(EditorState.createWithContent(stateFromHTML(props.data.briefDesc)))
                    setDescriptionChange(false)
                  }
                }}>Cancel</Button>
                {descriptionFetch.fetching ? (
                  <Button>
                    <Loader type="button" />
                  </Button>
                ) : (
                  <Button 
                    onClick={() => {
                      saveDescription();
                    }}
                  >Save</Button>
                )}
              </div>
            }
          </>
        ) : (
            // <>{ReactHtmlParser(urlify(props.data.briefDesc))}</>
            <div dangerouslySetInnerHTML={{ __html: urlify(props.data.briefDesc) }} />
            // <Linkify>
            //   {props.data.briefDesc}
            // </Linkify>
        )}

      </div>
    </ErrorBoundary>
  );
}

BriefInfo.defaultProps = {
  editMode: false
};

BriefInfo.protoTypes = {
  data: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  editDescription: PropTypes.func.isRequired,
  changeAssigneeWebdesigner: PropTypes.func.isRequired,
  changeAssigneeIntegrator: PropTypes.func.isRequired,
  changeWeight: PropTypes.func.isRequired,
  changePlatforms: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  allMarkets: formFieldsSelectors.getAllMarkets(state),
  assignees: briefSelectors.getAssignees(state),
  assigneeWebdesigner: briefSelectors.getAssigneeWebdesigner(state),
  assigneeIntegrator: briefSelectors.getAssigneeIntegrator(state),
  webdesignersList: usersSelectors.getWebdesigners(state),
  integratorsList: usersSelectors.getIntegrators(state),
  requests: briefSelectors.getRequests(state),
  descriptionFetch : briefSelectors.getFetchDescription(state),
  permissions: authSelectors.getPermissions(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editDescription: (description) => dispatch({ type: types.EDIT_BRIEF_DESCRIPTION_REQUEST, id: ownProps.data.briefId, description: description }),
  changeAssigneeWebdesigner: (assignees) => dispatch({ type: types.CHANGE_WEBDESIGNER_ASSIGNMENT_REQUEST, id: ownProps.data.briefId, assignees: assignees }),
  changeAssigneeIntegrator: (assignees) => dispatch({ type: types.CHANGE_INTEGRATOR_ASSIGNMENT_REQUEST, id: ownProps.data.briefId, assignees: assignees }), 
  changeWeight: (weight) => dispatch({ type: types.CHANGE_WEIGHT_REQUEST, id: ownProps.data.briefId, weight: weight }),
  changePlatforms: (platforms) => dispatch({ type: types.CHANGE_PLATFORMS_REQUEST, id: ownProps.data.briefId, platforms: platforms }),
  changeMarkets: (markets) => dispatch({ type: types.CHANGE_MARKETS_REQUEST, id: ownProps.data.briefId, markets: markets })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefInfo);