import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as types from '../../constants/actionTypes/briefs';
import * as selectors from '../../selectors/briefs';
import Searchbar from '../Searchbar';
import InlineEditor from '../InlineEditor/';
import styles from './scss/pageHeader.module.scss';

function PageHeader({ fetching, error, editBriefTitle, ...props }) {

    // let [briefTitle, setBriefTitle] = useState(props.title);

    return(
        <header className={styles.pageHeader + (props.children ? (' ' + styles.hasChildren) : '')}>

            <div className={styles.topHeader}>
                
                {props.editMode ? (
                    <div className={styles.pageTitleEdit}>
                        <InlineEditor 
                            // onChange={(title=>setBriefTitle(title))}
                            name="description"
                            value={props.title} 
                            type="input"
                            placeholder="Brief Title" 
                            loading={fetching}
                            error={error}
                            inlineEdit={(value)=>editBriefTitle(props.briefId, value)}
                            // onClick={(value) => editBriefTitle(props.briefId, value)}
                        />
                    </div>
                ) : (
                    <h1 className={styles.pageTitle}>{props.title}</h1>
                )}

                <div className={styles.topContainer}>
                    {props.children}

                    <div className={styles.headerSearchbar}>
                        <Searchbar
                            placeholder="Search"
                            searchQuery=""
                            onChange={() => {}} 
                            onSubmit={() => {}} />
                    </div>
                </div>

            </div>
            {props.subtitle &&
                <div className={styles.bottomHeader} >
                    <h2><img src={'/static/img/' + (props.briefType === 1 ? 'brief' : 'offer') + '.svg'} alt={props.briefType === 1 ? 'brief' : 'offer'} title={`${props.briefType === 1 ? 'Standard' : 'Offer'} brief`} /> { props.subtitle }</h2>
                </div>
            }
        </header>
    );
}

PageHeader.defaultProps = {
    editMode: false
};

PageHeader.protoTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.object,
    editMode: PropTypes.bool,
    briefId: PropTypes.number
}

const mapStateToProps = (state) => ({
    fetching: selectors.getUpdateBriefTitleFetching(state),
    error: selectors.getUpdateBriefTitleError(state)
});

const mapDispatchToProps = (dispatch) => ({
    editBriefTitle: (briefId, title) => dispatch({ type: types.EDIT_BRIEF_TITLE_REQUEST, id: briefId, title: title })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHeader);