import * as types from '../constants/actionTypes/briefs';

export const changeBriefVisibilityFilter = (visibilityFilter) => ({
    type: types.CHANGE_BRIEFS_VISIBILITY_FILTER,
    visibilityFilter
});

export const changeBriefTypesFilter = (briefTypes) =>({
    type: types.CHANGE_BRIEF_TYPES_FILTER,
    briefTypes
});

export const changeBriefMarketsFilter = (markets) =>({
    type: types.CHANGE_BRIEF_MARKETS_FILTER,
    markets
});

export const changeBriefStatesFilter = (states) => ({
    type: types.CHANGE_BRIEF_STATES_FILTER,
    states
});

export const changeBriefLiveDateFilter = (liveDate) => ({
    type: types.CHANGE_BRIEF_LIVE_DATE_FILTER,
    liveDate
});

export const changeBriefSearchFilter = (keywords) => ({
    type: types.CHANGE_BRIEF_SEARCH_FILTER,
    keywords
});

export const changeBriefSortFilter = (sortBy) => ({
    type: types.CHANGE_BRIEF_SORT,
    sortBy
});

export const changeBriefCreatorsFilter = (creators) => ({
    type: types.CHANGE_BRIEF_CREATORS_FILTER,
    creators
});

export const changeBriefAssigneeFilter = (assignees) => ({
    type: types.CHANGE_BRIEF_ASSIGNEES_FILTER,
    assignees
});

export const changeBriefGroupFilter = (groups) => ({
    type: types.CHANGE_BRIEF_GROUPS_FILTER,
    groups
});

export const resetFilters = () => ({
    type: types.RESET_FILTERS
});