import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BriefsActions from '../../actions/briefs';

import styles from './scss/stateCategory.module.scss';
import { isArray } from 'util';

function StateCategory({ actions, status, ...props }) {
  const [defaultStep, setDefaultStep] = useState(isArray(props.children) ? props.children.map(x => x.props.status).indexOf('process') : 0)

  const [selectedStep, setSelectedStep] = useState(defaultStep)

  useEffect(() => {
    setDefaultStep(isArray(props.children) ? props.children.map(x => x.props.status).indexOf('process') : 0);
    setSelectedStep(defaultStep)
  }, [status, props.children, defaultStep])


  let timeout = null;

  const statusStyle = styles[`${status}Status`]

  
  return (
    <div className={[styles.stepContainer, props.expanded?styles.stepContainerExpanded:''].join(' ')}>
      <div className={styles.stepItem + ' ' + statusStyle} 
        onClick={props.onClick} 
      >
        <div className={styles.stepContent}>
          <span className={styles.stepIcon}>{props.order}</span>
          <span>{props.title}</span>
        </div>
      </div>

      {props.expanded &&
        <div className={styles.childrenContainer}>
          {isArray(props.children) ?
            React.Children.map(props.children, (child, i) => {
              return React.cloneElement(child, {
                selected: selectedStep === i,
                onmouseenter: () => {
                  clearTimeout(timeout);
                  setSelectedStep(i)
                },
                onmouseleave: () => {
                  clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    setSelectedStep(defaultStep)
                  }, 500);
                }
              })
            })
            :
            props.children
          }
        </div>
      }
    </div>
  );
}

export default StateCategory

StateCategory.protoTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}