import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './scss/history.module.scss';
import urlify from '../../utils/urlify';

import moment from 'moment';

function History(props) {

    function displayIcon(mimeType) {
        return (
            <img src={'/static/img/' + (mimeType.indexOf('image/') >= 0 ? 'picture' : 'selected-file') + '.svg' } alt={mimeType} />
        );
    }

    function IsfirstEdit(log) {
        if(log.updateValues.before === '' || log.updateValues.before === null || (Array.isArray(log.updateValues.before) && log.updateValues.before.length === 0) ) {
            return true;                    
        } else {
            return false;
        }
    }

    function descAction(log) {        

        switch(log.logType) {
            case 'BRIEFCOMMENT':
                return 'wrote a comment';

            case 'BRIEFUPDATE':

                const firstEdit = IsfirstEdit(log);
                
                if(log.updateField === 'states') {
                    return 'changed state';
                } else if(log.updateField === 'assignees') {
                    if('asIntegrator' in log.updateDisplayValues && log.updateDisplayValues.asIntegrator === 1) {
                        return firstEdit ? 'assigned an integrator' : 'assigned a new integrator';
                    } else if('asWebdesigner' in log.updateDisplayValues && log.updateDisplayValues.asWebdesigner === 1) {
                        return firstEdit ? 'assigned a webdesigner' : 'assigned a new webdesigner';
                    }else {
                        return firstEdit ? 'assigned a user' : 'assigned a new user';
                    }                   
                } else if(log.updateField === 'markets') {
                    return firstEdit ? 'defined the markets list' : 'edited the markets list';
                } else if(log.updateField === 'briefTitle') {
                    return firstEdit ? 'defined the title' : 'edited the title';
                } else if(log.updateField === 'briefDesc') {
                    return firstEdit ? 'defined the description' : 'edited the description';
                } else if(log.updateField === 'platforms') {
                    return firstEdit ? 'defined the platforms list' : 'edited the platforms list';
                } else if(log.updateField === 'weightId') {
                    return firstEdit ? 'defined the weight value' : 'edited the weight value';
                } else if(log.updateField === 'briefActive') {
                    return 'closed the brief';
                } else if(log.updateField === 'briefLiveDate') {
                    return 'edited the live date';
                } else {
                    return firstEdit ? 'defined a briefinfo' : 'edited a brief info'
                }                

            case 'BRIEFDOCUMENT':
                if (log.logSpecialType) {
                    switch (log.logSpecialType) {
                        case 'DOCUMENTACTIVATE':
                            return 'displayed a document'
                        case 'DOCUMENTDESACTIVATE':
                            return 'discarded a document'
                        default: 
                            return null
                    }
                }
                return 'uploaded a document';
                
                    
            default:

                return null;
        }
    }

    function displayData(data) {

        if(Array.isArray(data)) {
            return data.join(', ');
        } else {
            return ReactHtmlParser(urlify(data));
        }
        
    }
    
    function generateLog(log) {

        switch(log.logType) {
            case 'BRIEFCOMMENT':
              return(
                <div className={styles.comment}>
                  {/* <span className={styles.updateInfo}>{ReactHtmlParser(urlify(log.commentMsg))}</span> */}
                  <span dangerouslySetInnerHTML={{ __html: urlify(log.commentMsg) }} />
                  {/* <span dangerouslySetInnerHTML={{ __html: urlify(`<p>Ceci est un lien <a href="http://localhost:3000/brief/1312" target="_self">soufien</a>&nbsp;</p>`) }} /> */}
                </div>
              );

            case 'BRIEFUPDATE':

                    if(log.updateField === 'states') {
                        
                        return(
                            <div>
                                <div className={styles.stateBloc}>
                                    <div className={styles[`iconCircle_${log.newStateLogo}`]}>
                                    </div>
                                    <span>{displayData(log.updateDisplayValues.after)}</span>
                                </div>
                                {log.updateDesc!=='<p></p>' &&
                                    <div className={styles.comment}>
                                        <span className={styles.updateInfo}>{displayData(log.updateDesc)}</span>
                                    </div>
                                }
                            </div>
                           
                        );
                    } else if(log.updateField === 'briefActive') {
                        return '';
                    }

                    let before = '', after = '';
                    if(log.updateField === 'briefLiveDate') {
                        before = moment(log.updateDisplayValues.before).format('ddd, YYYY/MM/DD');
                        after = moment(log.updateDisplayValues.after).format('ddd, YYYY/MM/DD');

                    } else {
                        before = displayData(log.updateDisplayValues.before);
                        after = displayData(log.updateDisplayValues.after);
                    }

                    return(
                        <div className={styles.updateDescription}>
                            <ul>
                                <li><span className={styles.after}>{after}</span></li>
                                {!IsfirstEdit(log) && <li className={styles.before}><span className={styles.before}>{before}</span></li>}                                
                             </ul>
                        </div>
                    );

            case 'BRIEFDOCUMENT':
                const deactivated = log.logSpecialType && log.logSpecialType === 'DOCUMENTDESACTIVATE'
                return(
                    <div className={styles.updateDescription} style={{opacity:deactivated?'0.3':'1'}}>
                        {displayIcon(log.documentMimeType)} <span className={styles.attachment} style={{textDecoration:deactivated?'line-through':'none'}}>{log.documentFilename}</span>&nbsp;<span className={styles.documentSize}>({log.documentSizeDisplay})</span>
                    </div>
                );

            case 'changeState':
                return(
                    <div className={styles.updateDescription}>
                      <div className={styles.newState}>{log.newState}</div>
                      <p>{log.content}</p>
                    </div>
                );
            
            case 'updateLiveDate':
                return(
                    <div className={styles.updateLiveDate}>
                        <h4>updated live date</h4>
                        <h4>{log.date}</h4>
                    </div>
                );
    
                
            case 'updateDescription':
                return(
                    <div className={styles.updateDescription}>
                        <h4>updated Brief Description</h4>
                    </div>
                );
    
            case 'changedAssignee':
                return(
                    <div className={styles.updateDescription}>
                        <h4>Assigned brief to {log.newAssignee.username}</h4>
                    </div>
                );
    
            case 'changedWeight':
                return(
                    <div className={styles.updateDescription}>
                        <h4>Update brief weight to {log.newWeight}</h4>
                    </div>
                );
    
            case 'changedPlatforms':
                return(
                    <div className={styles.updateDescription}>
                        <h4>Update brief platforms : {log.newPlatforms.join(', ')}</h4>
                    </div>
                );
                    
            default:

                return null;
        }
    }

    return(
        <div>
            {props.logs.map((log, i) => {
                let creation_date = moment(log.created_at);
                creation_date = creation_date.add(creation_date.utcOffset(), 'm');
                return (
                    <div className={styles.historyItemContainer} key={`log-${i}`}>
                        <div className={styles.stepNumber}>
                            {/* {i} */}
                        </div>
                        <div className={styles.historyItem}>
                            <div className={styles.logHeader}>
                                <h4 className={styles.username}>{log.user.userName} <span className={styles.descAction}>{descAction(log, i)}</span></h4>
                                <h4 className={styles.date}>
                                    <span className={styles.day}>{creation_date.format('ddd, YYYY/MM/DD')}</span>
                                    <span className={styles.time}>{creation_date.format('kk:mm')}</span>
                                
                                </h4>
                            </div>
                            {generateLog(log, i)}
                        </div>
                    </div>
                )
            })
            }
        </div>
    );
}


export default History;