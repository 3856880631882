import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as types from '../constants/actionTypes/briefs';
import { SHOW_ALL_BRIEFS, SORT_BY_LIVE_DATE } from '../constants/briefsVisibilityFilter';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { API_URI } from '../constants/config';
import { getToken } from '../selectors/auth';


function createBrief(form, files = null, token) {

    let formData = new FormData();
    let documentTitles = [];

    if(files !== null) {
        for(const [i, file] of files.entries()) {
            formData.append(`documentFiles[${i}]`, file.file, file.file.name);
            documentTitles.push(file.file.name);
        }
    }

    return axios({
        method: 'post',
        url: `${API_URI}/addBrief`,
        data: formData,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefTitle: form.title,
            briefDesc: form.description,
            typeId: form.type,
            weightId: form.weight,
            briefLiveDate: form.liveDate,
            markets: form.markets,
            ...(documentTitles.length > 0 ? { documentTitles: documentTitles } : {})
        }
    });
}

function loadBriefs(visibilityFilter = SHOW_ALL_BRIEFS, markets = [], types = [], states = [], liveDate = null, keywords, sortBy = SORT_BY_LIVE_DATE, assigneesList = [], groupsList = [], creatorsList = [], cursor = -1, token) {

    return axios({
        method: 'post',
        url: `${API_URI}/getBriefsList`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            total: 10,
            filter: visibilityFilter,
            marketId: markets,
            typeId: types,
            stateId: states,
            ...(liveDate !== null ? { briefLiveDate: `${liveDate.getFullYear()}-${liveDate.getMonth() + 1}-${liveDate.getDate()}` } : {}),
            ...(keywords.length > 1 ? { searchword: keywords } : {}),
            orderBy: sortBy,
            ...(cursor !== -1 ? { start: cursor } : {}),
            assignees: assigneesList || [], 
            groups: groupsList || [], 
            owners: creatorsList || []
        }
    });
}

function loadBrief(id, token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getBrief`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            briefId: id
        }
    });
}

function loadWeights(token) {
    return axios({
        method: 'get',
        url: `${API_URI}/getWeightsList`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

function addComment(briefId, comment, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/addComment`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: briefId,
            commentMsg: comment
        }
    });
}

function addFile(briefId, file, token) {

    let formData = new FormData();
    formData.append(`documentFile`, file, file.name);

    return axios({
        method: 'post',
        url: `${API_URI}/addDocument`,
        data: formData,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: briefId,
            documentTitle: file.name
        }
    });
}

function downloadFile(id, fileToken, token) {
    return axios({
        method: 'get',
        url: `${API_URI}/downloadDocument`,
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/vnd.ms-excel'
        },
        params: {
            documentToken: fileToken,
            documentId: id
        }
    });
}

function updateState(id, stateId, newStateId, validationComment = '', token) {
    return axios({
        method: 'post',
        url: `${API_URI}/updateState`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: id,
            stateId: stateId,
            newStateId: newStateId,
            ...(validationComment !== '' ? { updateDesc: validationComment } : {})
        }
    });
}

function parallelizeState(id, stateId, newStateId, validationComment = '', token) {
    return axios({
        method: 'post',
        url: `${API_URI}/parallelizeState`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: id,
            stateId: stateId,
            newStateId: newStateId,
            ...(validationComment !== '' ? { updateDesc: validationComment } : {})
        }
    });
}

function unparallelizeState(id, validationComment = '', token) {
    return axios({
        method: 'post',
        url: `${API_URI}/unparallelizeState`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: id,
            ...(validationComment !== '' ? { updateDesc: validationComment } : {})
        }
    });
}

function updateBrief(id, field, value, token, updateDesc = null) {
    return axios({
        method: 'post',
        url: `${API_URI}/updateBrief`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: id,
            dataField: field,
            dataValue: value,
            updateDesc: updateDesc
        }
    });
}

function updateBriefAssignee(id, field, value, role, token) {
    return axios({
        method: 'post',
        url: `${API_URI}/updateBrief`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: id,
            dataField: field,
            dataValue: value,
            ...(role === 'webdesigner' ? { asWebdesigner: 1 } : {}),
            ...(role === 'integrator' ? { asIntegrator: 1 } : {})
        }
    });
}

function AssignToMeBrief(briefId, asIntegrator, asWebdesigner, token){
    return axios({
        method: 'post',
        url: `${API_URI}/assignMe`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            briefId: briefId,
            asIntegrator: asIntegrator,
            asWebdesigner: asWebdesigner,
        }
    });
}


function deleteFileFromBrief(fileId, briefId, documentToken, token){
    return axios({
        method: 'delete',
        url: `${API_URI}/deleteDocument`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            documentId: fileId,
            briefId: briefId,
            documentToken: documentToken
        }
    });
}

function activateDocument(fileId, briefId, documentToken, token){
    return axios({
        method: 'post',
        url: `${API_URI}/activateDocument`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        params: {
            documentId: fileId,
            briefId: briefId,
            documentToken: documentToken
        }
    });
}

export function* workerCreateBrief(payload) {

    try{
        const token = yield select(getToken);
        const response = yield call(createBrief, payload.form, payload.files, token);
        const brief = response.data;

        yield put({ type: types.CREATE_BRIEF_SUCCESS, brief });

        yield put(push(`/brief/${brief[0].briefId}`));

    } catch (error) {
        yield put({ type: types.CREATE_BRIEF_FAILURE, error });
    }
}

export function* workerLoadAllBriefs(payload) {
    try{

        let assigneesList = payload.assigneesList ? payload.assigneesList.map(obj => obj.value) : [] ;
        let groupsList = payload.groupsList ? payload.groupsList.map(obj => obj.value) : [] ;
        let creatorsList = payload.creatorsList ? payload.creatorsList.map(obj => obj.value) : [];

        const token = yield select(getToken);
        const response = yield call(loadBriefs, payload.visibilityFilter, payload.markets, payload.briefTypes, payload.states, payload.liveDate, payload.keywords, payload.sortBy, assigneesList, groupsList, creatorsList, -1, token);
        const briefs = response.data;

        yield put({ type: types.LOAD_ALL_BRIEFS_SUCCESS, briefs });

    } catch (error) {
        yield put({ type: types.LOAD_ALL_BRIEFS_FAILURE, error });
    }
}

export function* workerLoadNextBriefs(payload) {
    try{        

        let assigneesList = payload.assigneesList ? payload.assigneesList.map(obj => obj.value) : [] ;
        let groupsList = payload.groupsList ? payload.groupsList.map(obj => obj.value) : [] ;
        let creatorsList = payload.creatorsList ? payload.creatorsList.map(obj => obj.value) : [];

        const token = yield select(getToken);
        const response = yield call(loadBriefs, payload.visibilityFilter, payload.markets, payload.briefTypes, payload.states, payload.liveDate, payload.keywords, payload.sortBy, assigneesList, groupsList, creatorsList, payload.cursor, token);
        const nextBriefs = response.data.items;

        yield put({ type: types.LOAD_NEXT_BRIEFS_SUCCESS, nextBriefs });

    } catch (error) {
        yield put({ type: types.LOAD_NEXT_BRIEFS_FAILURE, error });
    }
}

export function* workerLoadBrief(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(loadBrief, payload.id, token);
        const brief = response.data;

        yield put({ type: types.LOAD_BRIEF_SUCCESS, brief });

    } catch (error) {
        yield put({ type: types.LOAD_BRIEF_FAILURE, error});
    }
}


export function* workerLoadWeights() {
    try{
        const token = yield select(getToken);
        const response = yield call(loadWeights, token);
        const weights = response.data;

        yield put({ type: types.LOAD_BRIEFS_WEIGHTS_SUCCESS, weights });

    } catch (error) {
        yield put({ type: types.LOAD_BRIEFS_WEIGHTS_FAILURE, error });
    }
}

export function* workerAddComment(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(addComment, payload.briefId, payload.comment, token);
        const comment = response.data;

        yield put({ type: types.ADD_COMMENT_SUCCESS, comment });

    } catch (error) {
        yield put({ type: types.ADD_COMMENT_FAILURE, error });
    }
}

export function* workerAddFile(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(addFile, payload.briefId, payload.file, token);
        const file = response.data;

        yield put({ type: types.ADD_BRIEF_FILE_SUCCESS, file });

    } catch (error) {
        yield put({ type: types.ADD_BRIEF_FILE_FAILURE, error });
    }
}

export function* workerDownloadFile(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(downloadFile, payload.id, payload.fileToken, token);
        const file = response.data;

        yield put({ type: types.DOWNLOAD_FILE_SUCCESS });

        yield FileDownload(file, payload.fileName);

    } catch (error) {
        yield put({ type: types.DOWNLOAD_FILE_FAILURE, error });
    }
}

export function* workerUpdateState(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateState, payload.id, payload.stateId, payload.newStateId, payload.validationComment, token);
        const newStates = response.data.states;
        const log = response.data.log;
        const briefActive = response.data.briefActive;
        
        yield put({ type: types.UPDATE_STATE_SUCCESS, states: newStates, log: log, briefActive: briefActive});

    } catch (error) {
        yield put({ type: types.UPDATE_STATE_FAILURE, error });
    }
}

export function* workerParallelizeState(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(parallelizeState, payload.id, payload.stateId, payload.newStateId, payload.validationComment, token);
        const newStates = response.data.states;
        const log = response.data.log;
        
        yield put({ type: types.PARALLELIZE_STATE_SUCCESS, states: newStates, log: log });

    } catch (error) {
        yield put({ type: types.PARALLELIZE_STATE_FAILURE, error });
    }
}

export function* workerUnParallelizeState(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(unparallelizeState, payload.id, payload.validationComment, token);
        const newStates = response.data.states;
        const log = response.data.log;
        
        yield put({ type: types.UNPARALLELIZE_STATE_SUCCESS, states: newStates, log: log });

    } catch (error) {
        yield put({ type: types.UNPARALLELIZE_STATE_FAILURE, error });
    }
} 

export function* workerToggleCloseBrief(payload) {
    try{
        const token = yield select(getToken);
        const newStatus = payload.status === 0 ? 1 : 0;
        const closeComment = payload.closeComment || 'no close comment provided !'
        const response = yield call(updateBrief, payload.id, 'briefActive', newStatus, token, closeComment);
        const log = response.data[0];
        
        yield put({ type: types.TOGGLE_CLOSE_BRIEF_SUCCESS, status: newStatus, log: log });

    } catch (error) {
        yield put({ type: types.TOGGLE_CLOSE_BRIEF_FAILURE, error });
    }
}

export function* workerEditTitle(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBrief, payload.id, 'briefTitle', payload.title, token);
        const log = response.data[0];
        
        yield put({ type: types.EDIT_BRIEF_TITLE_SUCCESS, title: payload.title, log: log });

    } catch (error) {
        yield put({ type: types.EDIT_BRIEF_TITLE_FAILURE, error });
    }
}

export function* workerEditDescription(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBrief, payload.id, 'briefDesc', payload.description, token);
        const log = response.data[0];
        
        yield put({ type: types.EDIT_BRIEF_DESCRIPTION_SUCCESS, description: payload.description, log: log });

    } catch (error) {
        yield put({ type: types.EDIT_BRIEF_DESCRIPTION_FAILURE, error });
    }
}

export function* workerChangeLiveDate(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBrief, payload.id, 'briefLiveDate', payload.liveDate, token);
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_LIVE_DATE_SUCCESS, liveDate: payload.liveDate, log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_LIVE_DATE_FAILURE, error });
    }
}

export function* workerAssignWebdesigner(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBriefAssignee, payload.id, 'assignees', payload.assignees, 'webdesigner', token);
        const newAssignees = response.data[0].updatedList;
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_WEBDESIGNER_ASSIGNMENT_SUCCESS, newAssignees: newAssignees, log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_WEBDESIGNER_ASSIGNMENT_FAILURE, error });
    }
}

export function* workerAssignIntegrator(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBriefAssignee, payload.id, 'assignees', payload.assignees, 'integrator', token);
        const newAssignees = response.data[0].updatedList;
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_INTEGRATOR_ASSIGNMENT_SUCCESS, newAssignees: newAssignees, log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_INTEGRATOR_ASSIGNMENT_FAILURE, error });
    }
}

export function* workerChangeWeight(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBrief, payload.id, 'weightId', payload.weight.value, token);
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_WEIGHT_SUCCESS, weight: payload.weight, log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_WEIGHT_FAILURE, error });
    }
}

export function* workerChangePlatforms(payload) {
    try{
        const token = yield select(getToken);

        const platformsId = payload.platforms.map(platform => platform.value);
        const platforms = payload.platforms.map(platform => ({ platformId: platform.value, platformName: platform.label }));

        const response = yield call(updateBrief, payload.id, 'platforms', platformsId, token);
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_PLATFORMS_SUCCESS, platforms: platforms, log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_PLATFORMS_FAILURE, error });
    }
}

export function* workerChangeMarkets(payload) {
    try{
        const token = yield select(getToken);
        const response = yield call(updateBrief, payload.id, 'markets', payload.markets.map(market => market.value), token);
        const log = response.data[0];
        
        yield put({ type: types.CHANGE_MARKETS_SUCCESS, markets: payload.markets.map(market => ({marketId: market.value, marketSlug: market.label, marketFlag: `/${market.label}.svg` })), log: log });

    } catch (error) {
        yield put({ type: types.CHANGE_MARKETS_FAILURE, error });
    }
}

export function* workerAssignToMeBrief(payload){

    try{
        const token = yield select(getToken);
        const response = yield call(AssignToMeBrief, payload.briefId, payload.asIntegrator, payload.asWebdesigner, token);
        let updatedBrief = response.data[0];
        yield put({ type: types.UPDATE_BRIEF_IN_LIST, error: null, brief: updatedBrief});

    }catch(error){
        yield put({ type: types.ASSIGN_TO_ME_BRIEF_SUCCESS, error: error});
    }
}

export function* workerDeleteFileFromBrief(payload){
    try{
        const token = yield select(getToken);
        yield call(deleteFileFromBrief, payload.fileId, payload.briefId, payload.documentToken, token);        
        yield put({ type: types.DELETE_FILE_SUCCESS, fileId: payload.fileId});
    }catch(error){
        yield put({ type: types.DELETE_FILE_FAILURE, error: error});
    }
}

export function* workerEnableFileOnBrief(payload){
    try{
        const token = yield select(getToken);
        yield call(activateDocument, payload.fileId, payload.briefId, payload.documentToken, token);
        yield put({ type: types.ENABLE_FILE_SUCCESS, fileId: payload.fileId});
    }catch(error){
        yield put({ type: types.ENABLE_FILE_FAILURE, error: error});
    }
}

export function* workerModifyFilesInBrief(payload) {
    try{
        const token = yield select(getToken);
        for (const modif of payload.modifications) {
            if (modif.modifType === types.DELETE_FILE_REQUEST) {
                try{
                    const response = yield call(deleteFileFromBrief, modif.documentId, modif.briefId, modif.documentToken, token);
                    yield put({ type: types.DELETE_FILE_SUCCESS, log: response.data});
                }catch(error){
                    yield put({ type: types.DELETE_FILE_FAILURE, error: error});
                }
            }
            if (modif.modifType === types.ENABLE_FILE_REQUEST) {
                try{
                    const response = yield call(activateDocument, modif.documentId, modif.briefId, modif.documentToken, token);
                    yield put({ type: types.ENABLE_FILE_SUCCESS, log: response.data});
                }catch(error){
                    yield put({ type: types.ENABLE_FILE_FAILURE, error: error});
                }
            }
        } 
        yield put({ type: types.MODIFY_FILES_SUCCESS, docs: payload.modifications});
    }catch(error){
        yield put({ type: types.MODIFY_FILES_FAILURE, error: error});
    }
}

export function* watcherBriefs() {
    yield takeLatest(types.CREATE_BRIEF_REQUEST, workerCreateBrief);
    yield takeLatest(types.LOAD_ALL_BRIEFS_REQUEST, workerLoadAllBriefs);
    yield takeLatest(types.LOAD_NEXT_BRIEFS_REQUEST, workerLoadNextBriefs);
    yield takeLatest(types.LOAD_BRIEF_REQUEST, workerLoadBrief);
    yield takeLatest(types.ADD_COMMENT_REQUEST, workerAddComment);
    yield takeLatest(types.ADD_BRIEF_FILE_REQUEST, workerAddFile);
    yield takeLatest(types.ENABLE_FILE_REQUEST, workerEnableFileOnBrief);
    yield takeLatest(types.DELETE_FILE_REQUEST, workerDeleteFileFromBrief);
    yield takeLatest(types.MODIFY_FILES_REQUEST, workerModifyFilesInBrief);
    yield takeLatest(types.DOWNLOAD_FILE_REQUEST, workerDownloadFile);
    yield takeLatest(types.UPDATE_STATE_REQUEST, workerUpdateState);
    yield takeLatest(types.PARALLELIZE_STATE_REQUEST, workerParallelizeState);
    yield takeLatest(types.UNPARALLELIZE_STATE_REQUEST, workerUnParallelizeState);
    yield takeLatest(types.TOGGLE_CLOSE_BRIEF_REQUEST, workerToggleCloseBrief);
    yield takeLatest(types.EDIT_BRIEF_TITLE_REQUEST, workerEditTitle);
    yield takeLatest(types.EDIT_BRIEF_DESCRIPTION_REQUEST, workerEditDescription);
    yield takeLatest(types.CHANGE_LIVE_DATE_REQUEST, workerChangeLiveDate);
    yield takeLatest(types.CHANGE_WEBDESIGNER_ASSIGNMENT_REQUEST, workerAssignWebdesigner);
    yield takeLatest(types.CHANGE_INTEGRATOR_ASSIGNMENT_REQUEST, workerAssignIntegrator);
    yield takeLatest(types.CHANGE_WEIGHT_REQUEST, workerChangeWeight);
    yield takeLatest(types.CHANGE_PLATFORMS_REQUEST, workerChangePlatforms);
    yield takeLatest(types.CHANGE_MARKETS_REQUEST, workerChangeMarkets);
    yield takeLatest(types.ASSIGN_TO_ME_BRIEF_REQUEST, workerAssignToMeBrief);
}