import * as types from '../../constants/actionTypes/users';

const initialState = {
    allUsers: null,
    currentUser: null,
    invitedUsers: [],
    fetching: false,
    error: null
};

function users(state = initialState, action) {

    switch(action.type) {
        case types.LOAD_USERS_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_ALL_USERS_REQUEST:
            return { ...state, fetching: true, error: null };
            
        case types.LOAD_ALL_USERS_SUCCESS:
            return { ...state, fetching: false, allUsers: action.users };

        case types.LOAD_ALL_USERS_FAILURE:
            return { ...state, fetching: false, allUsers: null, error: action.error };

        case types.LOAD_USER_REQUEST:
            return { ...state, fetching: true, error: null };

        case types.LOAD_USER_SUCCESS:
            return { ...state, fetching: false, currentUser: action.user };

        case types.LOAD_USER_FAILURE:
            return { ...state, fetching: false, currentUser: null, error: action.error }

        case types.INVITE_USER_REQUEST:
            return { ...state, invitationRequestFetching: true, error: null, invitedUsers:[] };

        case types.INVITE_USER_SUCCESS:
            return { ...state, invitationRequestFetching: false, invitationRequestError: null, invitedUsers: [action.invitedUser, ...state.invitedUsers] };

        case types.INVITE_USER_FAILURE:
            return { ...state, invitationRequestFetching: false, invitationRequestError: action.error, invitedUsers:[] };

        case types.CLEAR_INVITE_USER:
            return { ...state, invitedUsers:[], invitationRequestError: null };

        case types.UPDATE_USER_ME_REQUEST:
            return { ...state, fetching: true, error: null, currentUser: action.user };

        case types.UPDATE_USER_ME_SUCCESS:
            return { ...state, fetching: false, error: null, currentUser: action.user };

        case types.UPDATE_USER_ME_FAILURE:
            return { ...state, fetching: false, error: action.error };

        case types.UPDATE_USER_ADMIN_REQUEST:
            return { ...state, fetching: true, error: null, currentUser: action.user };

        case types.UPDATE_USER_ADMIN_SUCCESS:
            return { ...state, fetching: false, error: null, currentUser: action.user };

        case types.UPDATE_USER_ADMIN_FAILURE:
            return { ...state, fetching: false, error: action.error };

        default:
            return state;
    }
}

export default users;